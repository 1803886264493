<template>
  <div class="domestic">
<!--    <div class="domestic-left medium-font">-->
<!--      <el-image :src="require('@/static/images/member/personal/domestic.png')" fit="cover" class="domestic-img"></el-image>-->
<!--      <div>{{ $t('personal.domestic') }}</div>-->
<!--    </div>-->
<!--    <div class="vertical-line"></div>-->
    <div>
      <div class="domestic-box u-between-flex default-font">
        <div>
          <div class="title">
            <div class="line"></div>
            <div>{{ $t('personal.package') }}</div>
          </div>
          <div class="u-between-flex">
            <div class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=unarrive')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.NotInCount || 0 }}</div>
                <div>{{ $t('personal.unarrive') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=arrived')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.InCount || 0 }}</div>
                <div>{{ $t('personal.arrived') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=waitPayment')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.WaitOrderCount || 0 }}</div>
                <div>{{ $t('personal.notplaceorder') }}</div>
              </div>
            </div>
            <div class="small-grid-line" v-if="IsMandatoryForecast"></div>
            <div v-if="IsMandatoryForecast" class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=claim')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.AwaitingClaimCount || 0 }}</div>
                <div>{{ $t('personal.placedorder') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="title">
            <div class="line"></div>
            <div>{{ $t('personal.order') }}</div>
          </div>
          <div class="u-between-flex">
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=unPaid')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.WaitPaymentCount || 0 }}</div>
                <div>{{ $t('personal.pendingPayment') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=underway')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.WaitDeliveryCount || 0 }}</div>
                <div>{{ $t('personal.toBeShipped') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=shipped')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.ShippedCount }}</div>
                <div>{{ $t('personal.shipped') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=completed')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.CompleteCount }}</div>
                <div>{{ $t('personal.completed') }}</div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="u-between-flex mt40">
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/address-domestic' })">{{ $t('personal.address') }}</div>
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/claim-domestic' })">{{ $t('personal.cargoclaim') }}</div>
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/consolidated-domestic' })">{{ $t('personal.combinedcargo') }}</div>
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/order-domestic' })">{{ $t('personal.myorder') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getConfig} from "@/api/system";
import { mapGetters } from "vuex"
export default {
  props: {
    countData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['TenantId'])
  },
  mounted() {
    getConfig({ TenantId: this.TenantId}).then(response => {
      const { IsMandatoryForecast } = response.Data
      this.IsMandatoryForecast = IsMandatoryForecast
    })
  },
  data() {
    return {
      IsMandatoryForecast: false
    }
  },
  methods: {
    routerTo(url) {
      this.$router.push({
        path: url
      })
    }
  }
}

</script>

<style scoped lang="scss">
.vertical-line {
  width: 0.2rem;
  height: 8rem;
  background-color: #F4F4F4;
}
.domestic {
  padding: 1.8rem 2.8rem;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .line {
      width: 6px;
      height: 12px;
      border-radius: 3px;
      background: #bb2232;
      margin-right: 5px;
    }
  }
  .domestic-left {
    text-align: center;
    .domestic-img {
      width: 7rem;
    }
  }
  .domestic-box {
    background-color: #FAFCFF;
    padding: 1.8rem 2.8rem;
    .domestic-box-img {
      width: 5rem;
      margin-right: 2.5rem;
    }
    .small-grid-line {
      width: 0.2rem;
      height: 4rem;
      background-color: #E4EEFE;
      margin: 0 3rem;
    }
  }
  .storehouse-btn {
    background-color: #FAFCFF;
    text-align: center;
    flex-basis: 23%;
    height: 5rem;
    line-height: 5rem;
    color: #194D8F;
    font-size: 1.7rem;
  }
}
</style>
