<template>
  <div class="container">
    <div class="notify">
      <el-image style="width: 100%; height: 55rem" :src="require('@/static/images/procedure/domestic/notify.png')"></el-image>
    </div>
    <div class="demonstrate">
      <div class="demonstrate-title mb30">{{ $t('procedure.process') }}</div>
      <div class="u-between-flex" style="align-items: flex-start">
        <div class="u-start-flex animated fadeInRight" :style="{ animationDelay: `${ 0.5 * i }s`}" v-for="(item, i) in demonstrate" :key="item.id">
          <div class="demonstrate-item">
            <el-image :src="require(`@/static/images/procedure/domestic/${item.path}`)" fit="cover" class="demonstrate-img"></el-image>
            <div class="default-font mb10">{{ item.title }}</div>
            <div class="default-font" v-if="item.sub_title">{{ item.sub_title }}</div>
          </div>
          <el-image v-if="i !== demonstrate.length -1" :src="require('@/static/images/procedure/domestic/arraw.png')" class="arraw"></el-image>
        </div>
      </div>
    </div>
    <div class="server">
      <div class="u-between-flex">
        <div class="server-item" v-for="item in servers" :key="item.id">
          <div class="server-img mb10">
            <el-image style="width: 100%; height: 22rem; border-radius: 0.8rem" fit="cover" :src="require(`@/static/images/procedure/domestic/${item.path}`)"></el-image>
          </div>
          <div class="server-title mb10">{{ item.title }}</div>
          <div class="introduces mb30">
            <div class="default-font mb10" v-for="(text, index) in item.introduces" :key="index">{{ text }}</div>
          </div>
<!--          <div>-->
<!--            <el-button class="server-btn">{{ $t('procedure.learnmore') }}</el-button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="introduce u-center-flex" style="align-items: flex-start">
      <el-image fit="cover" class="mr30 introduce-img" :src="require('@/static/images/procedure/domestic/warehouse.jpg')"></el-image>
      <div style="width: 50%">
        <div class="introduce-title mb30">{{ $t('procedure.warehouseIntroduction') }}</div>
        <div class="introduce-sub-title mb10">{{ $t('procedure.warehouseLocation') }}</div>
        <div class="introduce-sub-title mb10">{{ $t('procedure.warehouseFunction') }}</div>
<!--        <div class="introduce-sub-title mb10">{{ $t('procedure.warehouseArea') }}</div>-->
<!--        <div class="introduce-sub-title mb30">{{ $t('procedure.storagePeriod') }}</div>-->
        <div><el-button class="introduce-btn" @click="$router.push({ path: '/member/personal' })">{{ $t('procedure.checkMore') }}</el-button></div>
      </div>
    </div>
    <div class="count u-center-flex">
      <div class="mr30" style="flex-basis: 40%">
        <div class="count-title mb30">{{ $t('procedure.weightCalculation') }}</div>
        <div class="count-sub-title mb10">{{ $t('procedure.weightCalculationNote1') }}</div>
        <div class="count-sub-title mb10">{{ $t('procedure.weightCalculationNote2') }}</div>
        <div class="count-sub-title mb10">{{ $t('procedure.weightCalculationNote3') }}</div>
        <div class="count-sub-title mb10">{{ $t('procedure.weightCalculationNote4') }}</div>
        <div><el-button class="count-btn" @click="$router.push({ path: '/consolidation/volume-conversion' })">{{ $t('procedure.learnMore') }}</el-button></div>
      </div>
      <el-image fit="cover" class="count-img" :src="require('@/static/images/procedure/domestic/weight.jpg')"></el-image>
    </div>
    <div class="declaration u-center-flex" style="align-items: flex-start">
      <el-image fit="cover" class="mr30 declaration-img" :src="require('@/static/images/procedure/domestic/declaration.jpg')"></el-image>
      <div style="width: 50%">
        <div class="declaration-title mb30">{{ $t('procedure.declarationTitle') }}</div>
        <div class="declaration-sub-title mb10">{{ $t('procedure.declarationNote1') }}</div>
      </div>
    </div>
    <div class="package u-center-flex" style="align-items: flex-start">
      <div class="mr30" style="flex-basis: 45%">
        <div class="package-title mb30">{{ $t('procedure.shippingPackage') }}</div>
        <div class="package-sub-title mb10">{{ $t('procedure.packageNote1') }}</div>
        <div><el-button class="package-btn" @click="$router.push({ path: '/member/added' })">{{ $t('procedure.learnMore') }}</el-button></div>
      </div>
      <el-image fit="cover" class="package-img" :src="require('@/static/images/procedure/domestic/added.jpg')"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    demonstrate() {
      return [
        {
          id: 1,
          path: 'notebook.png',
          title: this.$t('procedure.demonstrate.step1.title'),
          sub_title: this.$t('procedure.demonstrate.step1.subTitle')
        },
        {
          id: 2,
          path: 'bus.png',
          title: this.$t('procedure.demonstrate.step2.title'),
          sub_title: this.$t('procedure.demonstrate.step2.subTitle')
        },
        {
          id: 3,
          path: 'ship.png',
          title: this.$t('procedure.demonstrate.step3.title'),
          sub_title: this.$t('procedure.demonstrate.step3.subTitle')
        },
        {
          id: 4,
          path: 'airplane.png',
          title: this.$t('procedure.demonstrate.step4.title'),
          sub_title: this.$t('procedure.demonstrate.step4.subTitle')
        },
        {
          id: 5,
          path: 'goods.png',
          title: this.$t('procedure.demonstrate.step5.title'),
          sub_title: this.$t('procedure.demonstrate.step5.subTitle')
        }
      ]
    } ,
    servers() {
      return [
        {
          id: 1,
          path: 'server-1.png',
          title: this.$t('procedure.servers.step1.title'),
          introduces: [
            this.$t('procedure.servers.step1.introduces.0'),
            this.$t('procedure.servers.step1.introduces.1'),
            this.$t('procedure.servers.step1.introduces.2'),
            this.$t('procedure.servers.step1.introduces.3')
          ]
        },
        {
          id: 2,
          path: 'server-2.png',
          title: this.$t('procedure.servers.step2.title'),
          introduces: [
            this.$t('procedure.servers.step2.introduces.0'),
            this.$t('procedure.servers.step2.introduces.1')
          ]
        },
        {
          id: 3,
          path: 'server-3.png',
          title: this.$t('procedure.servers.step3.title'),
          introduces: [
            this.$t('procedure.servers.step3.introduces.0'),
            this.$t('procedure.servers.step3.introduces.1'),
            this.$t('procedure.servers.step3.introduces.2')
          ]
        },
      ]
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.container {
  .notify {
    //background-image: url('../../../static/images/procedure/domestic/notify.png');
    //background-size: cover;
    //background-position: center center;
    //background-repeat: no-repeat;
    //padding-bottom: 27%;
  }
  .demonstrate {
    background-color: #fff;
    padding: 4.5rem 12%;
    .demonstrate-title {
      text-align: center;
      font-size: 2.4rem;
      color: #194D8F;
    }
    .arraw {
      width: 80px;
      height: 50px;
    }
    .demonstrate-item {
      flex-basis: 60%;
      text-align: center;
      .demonstrate-img {
        height: 10rem;
      }
    }
  }
  .server {
    padding: 6rem 10%;
    .server-item {
      flex-basis: 30%;
      .server-img {
        width: 100%;
        border-radius: 0.8rem;
      }
      .server-title {
        font-size: 2.4rem;
        color: #194D8F;
      }
      .introduces {
        width: 100%;
        height: 12rem;
      }
      .server-btn {
        background: #194D8F;
        border-radius: 0.2rem;
        color: #fff;
        width: 13rem;
        height: 4.5rem;
        vertical-align: center;
        font-size: 1.4rem;
      }
    }
  }
  .introduce {
    background-color: #fff;
    padding: 6rem 15%;
    .introduce-title {
      font-size: 2.4rem;
      color: #194D8F;
    }
    .introduce-img {
      height: 28rem;
      clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    }
    .introduce-sub-title {
      font-size: 1.6rem;
      color: #333333;
    }
    .introduce-btn {
      background: #194D8F;
      border-radius: 0.2rem;
      color: #fff;
      width: 13rem;
      height: 4.5rem;
      vertical-align: center;
      font-size: 1.4rem;
    }
  }
  .count {
    padding: 6rem 15%;
    .count-title {
      font-size: 2.4rem;
      color: #194D8F;
    }
    .count-img {
      height: 28rem;
      width: 45%;
      clip-path: polygon(100% 0, 0% 0, 20% 100%, 100% 100%);
    }
    .count-sub-title {
      font-size: 1.4rem;
      color: #333333;
    }
    .count-btn {
      background: #194D8F;
      border-radius: 0.2rem;
      color: #fff;
      width: 13rem;
      height: 4.5rem;
      vertical-align: center;
      font-size: 1.4rem;
    }
  }
  .declaration {
    background-color: #fff;
    padding: 6rem 15%;
    .declaration-title {
      font-size: 2.4rem;
      color: #194D8F;
    }
    .declaration-img {
      height: 25rem;
      width: 40%;
      clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    }
    .declaration-sub-title {
      line-height: 2;
      font-size: 1.6rem;
      color: #333333;
    }
  }
  .package {
    padding: 6rem 15%;
    .package-btn {
      background: #194D8F;
      border-radius: 0.2rem;
      color: #fff;
      width: 13rem;
      height: 4.5rem;
      vertical-align: center;
      font-size: 1.4rem;
    }
    .package-title {
      font-size: 2.4rem;
      color: #194D8F;
    }
    .package-img {
      clip-path: polygon(100% 0, 0% 0, 20% 100%, 100% 100%);
      height: 28rem;
    }
    .package-sub-title {
      line-height: 2;
      font-size: 1.6rem;
      color: #333333;
    }
  }
}
</style>
