<template>
  <div class="advantage">
    <div class="advantage-title">{{ $t('advantagebox.title') }}</div>
    <div class="advantage-container">
      <div class="advantage-item" v-for="item in advantages" :key="item.id">
        <el-image style="width: 8rem; height: auto" :src="require(`@/static/images/index/advantage/${item.icon}`)"></el-image>
        <div class="advantage-fst-title">{{ item.title }}</div>
        <div class="advantage-line"></div>
        <div class="advantage-sub-title">{{ item.sub_title1 }}</div>
        <div class="advantage-sub-title">{{ item.sub_title2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    advantages() {
      return [
        {
          id: 1,
          icon: 'team.png',
          title: this.$t('advantageBox.team.title'),
          sub_title1: this.$t('advantageBox.team.subtitle1'),
          sub_title2: this.$t('advantageBox.team.subtitle2')
        },
        {
          id: 2,
          icon: 'one.png',
          title: this.$t('advantageBox.qualityControl.title'),
          sub_title1: this.$t('advantageBox.qualityControl.subtitle1'),
          sub_title2: this.$t('advantageBox.qualityControl.subtitle2')
        },
        {
          id: 3,
          icon: 'safe.png',
          title: this.$t('advantageBox.innovativeTechnology.title'),
          sub_title1: this.$t('advantageBox.innovativeTechnology.subtitle1'),
          sub_title2: this.$t('advantageBox.innovativeTechnology.subtitle2')
        },
        {
          id: 4,
          icon: '24hour.png',
          title: this.$t('advantageBox.diverseService.title'),
          sub_title1: this.$t('advantageBox.diverseService.subtitle1'),
          sub_title2: this.$t('advantageBox.diverseService.subtitle2')
        }
      ]
    }
  },
  methods: {

  }

}

</script>

<style scoped lang="scss">
.advantage {
  width: 100%;
  background-image: url('../../../static/images/advantage-bgc.jpg');
  background-size: 100% auto; /* 设置背景图片按宽度等比例缩放 */
  background-repeat: no-repeat;
  background-position: center;
  filter: grayscale(50%);
  padding: 5rem 0;
  .advantage-title {
    text-align: center;
    font-size: 3rem;
    font-weight: 550;
    color: white;
    margin-bottom: 4rem;
  }
  .advantage-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .advantage-item {
      flex-basis: 22%;
      text-align: center;
      .advantage-fst-title {
        font-size: 2.4rem;
        margin: 2.5rem 0;
        color: #fff;
      }
      .advantage-line {
        width: 50%;
        height: 0.2rem;
        background-color: #307CC0;
        margin: 0 auto;
        margin-bottom: 2.5rem;
      }
      .advantage-sub-title {
        font-size: 1.2rem;
        color: #fff;
        margin: 1.2rem 0;
      }
    }
  }
}
</style>
