<template>
  <div class="container">
    <div class="u-between-flex mb30">
      <div class="notify-title">{{ $t('notify.title') }}</div>
      <div class="notify-title">{{ $t('notify.emailmsg') }}<span class="notify-msg"> ( {{ $t('notify.unverified') }} )</span></div>
    </div>
    <div class="u-between-flex">
      <div v-for="i in [1, 2]" :key="i" class="notify-box default-box">
        <div class="u-between-flex" v-for="j in [1,2,3,4]" :key="j">
          <el-image :src="require('@/static/images/member/notify/notify.png')" class="notify-img"></el-image>
          <div class="u-between-flex notify-flex-box">
            <div class="default-font">{{ $t('notify.latestnotice') }}</div>
            <el-switch v-model="value" active-color="#194D8F">
            </el-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="notify-title mb30 mt20">{{ $t('notify.intelligentservice') }}</div>
    <div class="u-between-flex default-box" style="padding: 1.5rem">
      <div class="u-start-flex">
        <el-image :src="require('@/static/images/member/notify/notify.png')" class="notify-img mr30"></el-image>
        <div class="default-font">{{ $t('notify.rem') }}</div>
      </div>
      <el-switch v-model="value" active-color="#194D8F"></el-switch>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: false
    }
  }
}

</script>

<style scoped lang="scss">
.notify-title {
  font-size: 1.6rem;
  color: #194D8F;
}

.default-font {
  font-size: 1.6rem;
}
.notify-msg {
  font-size: 1.6rem;
  color: #4FC2E9;
}
.notify-img {
  width: 3.2rem;
}
.container {
  padding: 2rem 1rem;
  .notify-box {
    padding: 0 1.5rem;
    flex-basis: 45%;
    .notify-flex-box {
      width: 80%;
      border-bottom: 0.1rem solid #E6E6E6;
      padding: 2rem 0
    }
    .notify-flex-box:nth-child(4),
    .notify-flex-box:nth-child(8) {
      border: none;
    }
  }
}
</style>
