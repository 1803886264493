<template>
  <div class="container">
    <div class="title">{{ navigationBar.head }}</div>
    <div class="item" v-html="content" v-loading="loading"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      loading: false,
      name: ""
    }
  },
  computed: {
    navigationBar() {
      let type = "";
      let head = "";
      if (this.name === 'about-Us') {
        head = this.$t('service.aboutUs');
        type = 3;
      } else if (this.name === 'privacy') {
        head = this.$t('service.privacyStatement');
        type = 4;
      } else if (this.name === 'term') {
        head = this.$t('service.termsAndDisclaimer');
        type = 6;
      } else if (this.name === 'carriage-treaty') {
        head = this.$t('service.carriageAgreement');
        type = 7;
      } else if (this.name === 'contact-Us') {
        head = this.$t('service.contactUs');
        type = 8;
      }
      return { type, head };
    }
  },
  methods: {
    getData() {
      this.name = this.$route.params.name
      document.title = this.navigationBar.head
      this.loading = true
      this.$store.dispatch('webSite/getShopMatters', this.navigationBar.type).then(data => {
        this.loading = false
        this.content = data[0].Content
        this.$forceUpdate()
      })
    }
  },
  created() {
    this.getData()
  },
  watch: {
    '$route': {
      handler() {
        this.getData()
      },
      deep: true
    }
  }
}

</script>

<style scoped lang="scss">
.container {
  padding: 2rem 1.5rem;
  min-height: calc(100vh - (10rem + 36rem));
  .item {
    padding: 1.5rem;
    background: #F7F8FA;
  }
  .title {
    font-size: 1.6rem;
    color: #194D8F;
    padding: 1.5rem;
    border-bottom: 0.1rem solid rgba(230,230,230,1);
  }
}
</style>
