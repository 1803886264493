<template>
  <div class="container">
    <div class="coupon-nav u-center-flex mb30">
      <button class="cu-btn nav-btn u-flex-col u-row-center u-col-center pointer"
              :class="{ 'btn-active': nav.id == stateCurrent }" v-for="nav in couponsState" :key="nav.id"
              @click="onNav(nav)">
        <span class="item-title">{{ nav.title }}</span>
      </button>
    </div>
    <div v-if="stateCurrent===1">
      <div class="message-box mb10">
        <el-table
            @row-click="handleClick"
            :data="tableData"
            :header-cell-style="tableHeaderPadding"
            style="width: 100%;border: 0.1rem solid rgba(230,230,230,1);">
          <el-table-column prop="date" :label="$t('message.date')" width="270">
            <template slot-scope="scope">
              <div class="un-read-box">
                <div class="read-mark" v-if="!scope.row.IsLook && !reading.includes(scope.row.Id) && !scope.row.TopState"></div>
                <el-image v-else-if="scope.row.TopState && !reading.includes(scope.row.Id)" :src="require('@/static/images/message/topState.png')" class="top-img"></el-image>
                <el-image v-else-if="scope.row.TopState && reading.includes(scope.row.Id)" :src="require('@/static/images/message/unTopState.png')" class="top-img"></el-image>
                <div :class="[ reading.includes(scope.row.Id) ? 'grey pointer' : 'pointer' ]">{{ scope.row.CreateTime | formatDate }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="content" :label="$t('message.content')">
            <template slot-scope="scope">
              <span :class="[ reading.includes(scope.row.Id) ? 'grey pointer' : 'pointer' ]" :style="{'font-size': '1.3rem', 'color': scope.row.read ? '#B2B2B2' : '#194D8F'}">{{ scope.row.Name }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="10"
            background
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <div v-else>
      <div class="message-box mb10">
        <div class="card-box u-start-flex pointer" v-for="item in tableData" :key="item.Id">
          <el-image :preview-src-list="[item.PosterImgHostPath]" :src="item.PosterImgHostPath" fit="cover" class="message-img mr30">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
          <div>
            <div class="mb10">
              <span class="message-title mr15">{{ item.Name }}</span>
            </div>
            <div class="default-font mb10">{{ item.Rem }}</div>
            <div class="default-font mb10">{{ $t('message.activeTime') }}：{{ item.CreateTime | format }} —— {{ item.ExpiredTime | format }}</div>
          </div>
          <div v-if="item.IsEffective === 3" style="font-size: 4.2rem; color: #E3E3E3; font-weight: bold" class="linear">{{ item.ActiveState }}</div>
          <linear-gradient-text v-else class="linear" :text="item.ActiveState"></linear-gradient-text>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="10"
            background
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {getNotices, getShopEvents} from "@/api/system";
import LinearGradientText from "@/components/LinearGradientText.vue";
import moment from 'moment';
export default {
  data() {
    return {
      stateCurrent: 1,
      currentPage: 1,
      tableData: [],
      pageSize: 10,
      total: 200,
    };
  },

  computed: {
    couponsState() {
      return [
        {
          id: 1,
          type: 'up-to-date',
          title: this.$t('message.couponsState.upToDate')
        },
        {
          id: 2,
          type: 'preferential',
          title: this.$t('message.couponsState.preferential')
        },
      ]
    } ,
    ...mapGetters(['shopID', 'reading'])
  },
  created() {
    this.getData()
  },
  components: {
    LinearGradientText
  },
  filters: {
    format(value, format = 'YYYY-MM-DD') {
      if (!value) return '';
      return moment(value).format(format);
    }
  },
  methods: {
    onNav(nav) {
      if (nav.id === this.stateCurrent) return;
      this.stateCurrent = nav.id;
      this.currentPage = 1;
      this.tableData = []
      this.getData()
    },
    getData() {
      if (this.stateCurrent === 1) {
        const data = {
          PageIndex: this.currentPage,
          PageRows: this.pageSize,
          ShopId: this.shopID
        }
        getNotices(data).then(response => {
          const { Code, Msg, Data, Total } = response
          if (Code === 200) {
            this.tableData = Data
            this.total = Total
          } else {
            this.$message.warning(Msg)
          }
        })
      } else {
        const data = {
          PageIndex: this.currentPage,
          PageRows: this.pageSize,
          ShopId: this.shopID
        }
        getShopEvents(data).then(response => {
          const { Code, Msg, Data, Total } = response
          if (Code === 200) {
            this.tableData = Data
            this.total = Total
          } else {
            this.$message.warning(Msg)
          }
        })
      }
    },
    handleClick(row) {
      this.$store.dispatch('webSite/addRecord', row.Id)
      let routeUrl = this.$router.resolve({
        path: "/news-detail",
        query: { id: row.Id }
      });
      window.open(routeUrl.href, '_blank');
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData()
    },
    // eslint-disable-next-line no-unused-vars
    tableHeaderPadding({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return { 'padding-left': '4rem' }
      }
    }
  }
}

</script>

<style scoped lang="scss">
.grey {
  color: #909399 !important;
}
.top-img {
  width: 4rem;
  height: 2rem;
  margin-right: 5px;
}
.linear {
  margin-left: auto;
}
.read-mark {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: #FF1414;
  margin-right: 1rem;
}
.un-read-box {
  padding-left: 4rem;
  transform: translateX(-1.6rem);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #194D8F;
  font-size: 1.3rem;
}
.read-box {
  padding-left: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #B2B2B2;
  font-size: 1.3rem;
}
.container {
  width: 75%;
  margin: auto;
  background-color: #fff;
  padding: 2rem 2.5rem;
  min-height: calc(100vh - (39rem));
  box-sizing: border-box;
  .coupon-nav {
    background: #fff;
    height: 5rem;
    padding: 0 1.5rem;
    .nav-btn {
      margin-right: 1rem;
      color: #373737;
      width: 20%;
      height: 3.6rem;
      background-color: #fff;
      border-radius: 1.8rem;
      border: 0.1rem solid rgba(151,151,151,1);
    }

    .btn-active {
      color: #fff;
      background-color: #194D8F;
      border: none;
    }
  }
  .message-box {
    .card-box {
      border: 0.1rem solid rgba(227,227,227,1);
      border-radius: 0.6rem;
      padding: 2rem 10% 2rem 2.5rem;
      .message-img {
        width: 22rem;
        height: 12rem;
      }
      .message-title {
        font-size: 1.8rem;
        font-weight: 550;
        color: #194D8F;
      }
    }
  }
  .pagination-box {
    text-align: right;
  }
}
</style>
