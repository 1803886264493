<template>
  <div class="user-info">
    <div class="u-between-flex" style="padding-right: 5rem">
      <div class="user-headers u-start-flex">
        <div class="round-image">
          <el-avatar :size="60" :src="userInfo.HostPortrait"></el-avatar>
        </div>
        <div>
          <div style="margin-bottom: 1rem" class="medium-font">{{ $t('personal.username') }}：{{ userInfo.Name }}</div>
          <div class="default-font">{{ $t('personal.accountnumber') }}：{{ userInfo.UserName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('personal.membernumber') }}：{{ userInfo.Id }}</div>
        </div>
      </div>
      <div class="u-start-flex">
        <div class="u-start-flex sign-btn pointer mr10" @click="showSign=true">
          <el-image :src="require('@/static/images/member/personal/sign.png')" class="sign-icon"></el-image>
          <div>{{ $t('personal.sign') }}</div>
        </div>
        <div class="u-start-flex recharge-btn pointer mr10" @click="show=true">
          <el-image :src="require('@/static/images/member/personal/money.png')" class="recharge-icon"></el-image>
          <div>{{ $t('personal.recharge') }}</div>
        </div>
        <div class="u-start-flex withdrawal-btn pointer" @click="showWithdrawal=true">
          <el-image :src="require('@/static/images/member/personal/withdrawal.png')" class="withdrawal-icon"></el-image>
          <div>{{ $t('personal.withdrawalMoneyTitle') }}</div>
        </div>
      </div>
    </div>
    <div class="user-business u-between-flex" @click="toPage('Recommend')">
      <div class="u-start-flex pointer">
        <el-image :src="require('@/static/images/member/personal/location.png')" class="member-img"></el-image>
        <div>
          <div class="mb8">{{ $t('personal.recommend') }}</div>
          <div style="color: #FF9C3A">{{ $t('personal.commission') }}</div>
        </div>
      </div>
      <div class="u-start-flex pointer" @click="toPage('Agent')">
        <el-image :src="require('@/static/images/member/personal/coupon.png')" class="member-img"></el-image>
        <div>
          <div class="mb8">{{ userInfo.WithdrawalMoney }}</div>
          <div style="color: #3889FF">{{ $t('personal.withdrawal') }}</div>
        </div>
      </div>
      <div class="grey-line"></div>
      <div class="u-start-flex pointer" @click="toPage('flow')">
        <el-image :src="require('@/static/images/member/personal/amount.png')" class="member-img"></el-image>
        <div>
          <div style="font-size: 1.6rem; color: #5DB646; text-align: center">{{ userInfo.Money }}</div>
          <div>{{ $t('personal.amount') }}</div>
        </div>
      </div>
      <div class="u-start-flex pointer" @click="toPage('coupon')">
        <el-image :src="require('@/static/images/member/personal/integral.png')" class="member-img"></el-image>
        <div>
          <div style="font-size: 1.6rem; color: #4BA7F9">{{ Integral }}</div>
          <div>{{ $t('personal.integral') }}</div>
        </div>
      </div>
      <div class="u-start-flex pointer" @click="toPage('coupon')">
        <el-image :src="require('@/static/images/member/personal/roll.png')" class="member-img"></el-image>
        <div>
          <div style="font-size: 1.6rem; color: #F8AE00; text-align: center">{{ CouponCount }}</div>
          <div>{{ $t('personal.coupon') }}</div>
        </div>
      </div>
    </div>
    <div class="edit-user pointer" @click="$router.push({ path: '/member/account' })">
      <i class="el-icon-edit"></i>
    </div>
    <withdrawal-box :show.sync="showWithdrawal" @close="showWithdrawal=false"></withdrawal-box>
    <sign-box :show.sync="showSign" @close="showSign=false"></sign-box>
    <recharge-box :show.sync="show" @close="closeVisible" @handle="handleRecharge"></recharge-box>
    <cashier :recharge-number="rechargeNumber" :show="cashierShow" :title="$t('personal.balance')" orderType="recharge" @close="handleClose"></cashier>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import RechargeBox from "@/components/RechargeBox.vue";
import Cashier from "@/components/cashier.vue";
import SignBox from "@/components/SignBox.vue";
import WithdrawalBox from "@/components/WithdrawalBox.vue";
export default {
  data() {
    return {
      show: false,
      cashierShow: false,
      rechargeNumber: undefined,
      showWithdrawal: false,
      showSign: false
    }
  },
  computed: {
    ...mapGetters(["userInfo", "Integral", "CouponCount"])
  },
  components: {
    RechargeBox,
    Cashier,
    SignBox,
    WithdrawalBox
  },
  methods: {
    closeVisible() {
      this.show = false
    },
    handleClose() {
      this.cashierShow = false;
      this.$store.dispatch('user/getInfo')
    },
    handleRecharge(number) {
      this.rechargeNumber = number
      this.show = false;
      this.cashierShow = true
    },
    toPage(name) {
      this.$router.push({
        name: name
      })
    }
  }
}

</script>

<style scoped lang="scss">
.member-img {
  width: 4rem;
  margin-right: 1rem;
}
.user-info {
  position: relative;
  .user-headers {
    padding: 2rem 2.5rem;
    border-bottom: 0.1rem solid #F5F9FE;
    .round-image {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      margin-right: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .recharge-btn {
    color: #ffffff;
    background-color: #2EE424;
    padding: 0.8rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 1.4rem;
    .recharge-icon {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
  .withdrawal-btn {
    color: #ffffff;
    background-color: #dec20a;
    padding: 0.8rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 1.4rem;
    .withdrawal-icon {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
  .sign-btn {
    color: #ffffff;
    background-color: #bb2232;
    padding: 0.8rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 1.4rem;
    .sign-icon {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
  .user-business {
    font-size: 1.2rem;
    padding: 2rem 2.5rem 3rem 2.5rem;
    color: #333333;
    .grey-line {
      width: 0.1rem;
      height: 4rem;
      background-color: #F5F9FE;
    }
  }
  .edit-user {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    font-size: 1.6rem;
  }
}
</style>
