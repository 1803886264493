<template>
  <div class="tools animated fadeIn">
    <div class="box" v-for="item in tools" :key="item.id">
      <el-image class="box-img" :src="require(`@/static/images/layout/tools-box/${item.icon}`)"></el-image>
      <div class="hover-able">
        <div class="router-font mb10" @click="$router.push({ path: item.path })">{{ item.title }}</div>
        <div class="tool-font">{{ item.sub_title }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  },
  computed: {
    tools() {
      return [
        {
          id: 1,
          title: this.$t('toolBox.teach.title'),
          sub_title: this.$t('toolBox.teach.subtitle'),
          icon: 'teach.png',
          path: '/consolidation/teach'
        },
        {
          id: 2,
          title: this.$t('toolBox.volume.title'),
          sub_title: this.$t('toolBox.volume.subtitle'),
          icon: 'volume.png',
          path: '/consolidation/volume-conversion'
        },
        {
          id: 3,
          title: this.$t('toolBox.shipping.title'),
          sub_title: this.$t('toolBox.shipping.subtitle'),
          icon: 'shipping.png',
          path: '/consolidation/shipping-calculation'
        },
        {
          id: 4,
          title: this.$t('toolBox.chargeIntroduction.title'),
          sub_title: this.$t('toolBox.chargeIntroduction.subtitle'),
          icon: 'price.png',
          path: '/charge-introduce'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.hover-able:hover {
  animation: pulse;
  animation-duration: 1s;
}
.tools {
  display: flex;
  justify-content: space-between;
  animation-duration: 3s;
  height: 10rem; /* 调整容器的高度 */
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  position: relative;
  z-index: 50;
  .box {
    flex-basis: 25%;
    border-right: 0.1rem solid #F2F2F2;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .box-img {
      width: 5rem;
    }
    .tool-font {
      font-size: 1.4rem;
      color: #757575;
    }
    .router-font {
      font-size: 1.8rem;
      color: #373737;
    }
    .router-font:hover {
      color: #415DCC;
      cursor: pointer;
    }
  }
  /* 去除最后一个盒子的右边框 */
  .box:last-child {
    border-right: none;
  }
}
</style>
