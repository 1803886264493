<template>
  <div class="container">
    <!-- 轮播图   -->
    <el-carousel arrow="always" height="55rem">
      <el-carousel-item v-for="item in carouselData" :key="item.Id">
        <el-image class="carousel-img" fit="cover" :src="item.ImgHostURL"></el-image>
      </el-carousel-item>
    </el-carousel>
    <!-- 小工具   -->
    <tools-box></tools-box>
    <!-- 核心业务   -->
    <core-box></core-box>
    <!-- 优势   -->
    <advantage-box></advantage-box>
    <!-- 展示exhibit   -->
    <exhibit-box></exhibit-box>
    <!-- 评论盒子   -->
    <comment-box></comment-box>
    <!-- 底部   -->
    <footer-box></footer-box>
  </div>
</template>

<script>
import ToolsBox from "@/views/layout/components/tools-box.vue";
import CoreBox from "@/views/layout/components/core-box.vue";
import AdvantageBox from "@/views/layout/components/advantage-box.vue";
import ExhibitBox from "@/views/layout/components/exhibit-box.vue";
import FooterBox from "@/views/layout/components/footer-box.vue";
import CommentBox from "@/views/layout/components/comment-box.vue"
export default {
  data() {
    return {
      carouselData: []
    }
  },
  mounted() {
    this.$store.dispatch('webSite/carouselInit', 1).then(data => {
      this.carouselData = data;
    })
  },
  methods: {
  },
  components: {
    ToolsBox,
    CoreBox,
    AdvantageBox,
    ExhibitBox,
    CommentBox,
    FooterBox
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__indicators--horizontal {
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel-img {
  width: 100%;
  height: 55rem;
}

</style>
