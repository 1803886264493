<template>
  <el-form size="mini" ref="ruleForm" label-width="90px" :rules="rules" label-position="left" :model="form">
    <div class="u-between-flex" v-if="IsDeclare">
      <el-form-item :label="$t('mergerCard.DeclareName')" prop="DeclareName" style="flex-basis: 45%">
        <el-input v-model="form.DeclareName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.DeclarePhone')" prop="DeclarePhone" style="flex-basis: 45%">
        <el-input v-model="form.DeclarePhone"></el-input>
      </el-form-item>
    </div>
    <div class="u-between-flex" v-if="IsDeclare">
      <el-form-item :label="$t('mergerCard.DeclareAddress')" prop="DeclareAddress	" style="flex-basis: 45%">
        <el-input v-model="form.DeclareAddress"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.DeclareIdCard')" prop="DeclareIdCard" style="flex-basis: 45%">
        <el-input v-model="form.DeclareIdCard"></el-input>
      </el-form-item>
    </div>
    <div class="u-between-flex" v-if="IsDeclare">
      <el-form-item :label="$t('mergerCard.DeclarePostCode')" prop="DeclarePostCode" style="flex-basis: 45%">
        <el-input v-model="form.DeclarePostCode"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.DeclareEmail')" prop="DeclareEmail" style="flex-basis: 45%">
        <el-input v-model="form.DeclareEmail"></el-input>
      </el-form-item>
    </div>
    <div class="u-between-flex" v-if="IsDeclare">
      <el-form-item :label="$t('mergerCard.DeclareTelephone')" prop="DeclareTelephone" style="flex-basis: 45%">
        <el-input v-model="form.DeclareTelephone"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.DeclareMoney')" prop="DeclareMoney" style="flex-basis: 45%">
        <el-input type="number" disabled v-model="form.DeclareMoney"></el-input>
      </el-form-item>
    </div>
    <div class="u-between-flex" v-if="IsDeclare">
      <el-form-item :label="$t('mergerCard.DeclarePrice')" prop="DeclarePrice" style="flex-basis: 45%">
        <el-input type="number" v-model="form.DeclarePrice"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.DeclareAccount')" prop="DeclareAccount" style="flex-basis: 45%">
        <el-input type="number" v-model="form.DeclareAccount"></el-input>
      </el-form-item>
    </div>
    <div class="u-between-flex">
      <el-form-item :label="$t('mergerCard.GoodsName')" prop="GoodsName" style="flex-basis: 45%">
        <el-input v-model="form.GoodsName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.GoodsName_En')" prop="GoodsName_En" style="flex-basis: 45%">
        <el-input v-model="form.GoodsName_En"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
export default {
  name: "OrderForm",
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    IsDeclare: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rules() {
      return {
        GoodsName: [{ required: true, message: this.$t('mergerCard.placeholderGoodsName'), trigger: 'blur' }],
        DeclarePhone: [
            { required: true, message: this.$t('mergerCard.validateMobile'), trigger: 'blur' },
            // {validator: this.validateMobile, trigger: 'blur'}
        ],
        // DeclareEmail: [
        //     { required: true, message: this.$t('mergerCard.validateEmail'), trigger: 'blur' },
        //     {validator: this.validateEmail, trigger: 'blur'}
        // ],
        DeclareTelephone: [
            { required: true, message: this.$t('mergerCard.validateLandline'), trigger: 'blur' },
            // {validator: this.validateLandline, trigger: 'blur'}
        ],
        // DeclarePostCode: [
        //     { required: true, message: this.$t('mergerCard.validatePostalCode'), trigger: 'blur' },
        //     {validator: this.validatePostalCode, trigger: 'blur'}
        // ],
        DeclareIdCard: [
            { required: true, message: this.$t('mergerCard.validateCreditCode'), trigger: 'blur' },
            {validator: this.validateCreditCode, trigger: 'blur'}
        ],
        // GoodsName_En: [{ required: true, message: this.$t('mergerCard.placeholderGoodsName_En'), trigger: 'blur' }],
      }
    },
  },
  methods: {
    validatePostalCode(rule, value, callback) {
      // 中国大陆邮政编码的正则表达式
      const reg = /^[1-9]\d{5}$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('mergerCard.validatePostalCode') || ""));
      } else {
        callback();
      }
    },
    validateLandline(rule, value, callback) {
      // 中国大陆座机号码正则表达式
      const reg = /^0\d{2,3}-?\d{7,8}$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('mergerCard.validateLandline') || ""));
      } else {
        callback();
      }
    },
    validateEmail(rule, value, callback) {
      // 邮箱地址的正则表达式
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('mergerCard.validateEmail') || ""));
      } else {
        callback();
      }
    },
    validateMobile(rule, value, callback) {
      const reg = /^1[3-9]\d{9}$/; // 中国大陆手机号正则表达式
      if (!reg.test(value)) {
        callback(new Error(this.$t('mergerCard.validateMobile') || ""));
      } else {
        callback();
      }
    },
    validateCreditCode(rule, value, callback) {
      // 中国大陆统一社会信用代码的正则表达式
      const reg = /^[A-Z0-9]{18}$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('mergerCard.validateCreditCode') || ""));
      } else {
        callback();
      }
    },
    submitForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        });
      })
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-form-item__label {
  font-size: 1.4rem;
}
::v-deep .el-form-item {
  margin-bottom: 1.5rem;
}
</style>
