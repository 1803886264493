<template>
  <div class="container">
    <div class="volume-title">{{ $t('volume.title') }}</div>
    <div class="computed-box">
      <div class="computed-body">
        <div class="form-box">
          <el-form ref="ruleForm" :rules="rules" label-position="left" :model="form" label-width="auto">
            <el-form-item :label="$t('shippingcalculation.warehouseName')" prop="warehouse">
              <el-select style="width: 40rem;" v-model="form.warehouse">
                <el-option
                    v-for="item in warehouseOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('volume.length')" prop="length">
              <el-input style="width: 40rem;" v-model="form.length" :placeholder="$t('volume.lengthplaceholder')">
                <template slot="append">cm</template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('volume.width')" prop="width">
              <el-input style="width: 40rem;" v-model="form.width" :placeholder="$t('volume.lengthplaceholder')">
                <template slot="append">cm</template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('volume.height')" prop="height">
              <el-input style="width: 40rem;" v-model="form.height" :placeholder="$t('volume.heightplaceholder')">
                <template slot="append">cm</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-image class="box-img" fit="cover" :src="require('@/static/images/Consolidation-Notice/volume/box.png')"></el-image>
      </div>
      <div style="width: 100%; text-align: center"><el-button v-loading="loading" type="primary" class="sub-btn" @click="computedVolume('ruleForm')">{{ $t('volume.compute') }}</el-button></div>
      <div class="computed-result mb20">{{ $t('volume.result') }}：{{ weight }}KG</div>
      <div class="computed-notice" v-html="content">
<!--        <p>{{ $t('volume.notice1') }}</p>-->
<!--        <p>{{ $t('volume.notice2') }}</p>-->
<!--        <p>{{ $t('volume.notice3') }}</p>-->
<!--        <p>{{ $t('volume.notice4') }}</p>-->
<!--        <p>{{ $t('volume.notice5') }}</p>-->
      </div>
    </div>
  </div>
</template>

<script>

import {computedVolume} from "@/api/system";
import { getVirtualWareHouse } from "@/api/member"
import { mapGetters } from "vuex"

export default {
  methods: {
    getData() {
      const data = {
        PageIndex: 1,
        PageRows: 20,
        TenantId: this.TenantId,
        ShopId: this.shopID,
      }
      getVirtualWareHouse(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.warehouseOption = Data.map(item => { return { label: item.Name, value: item.Id } })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    computedVolume(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            Length: this.form.length || undefined,
            Width: this.form.width || undefined,
            Height: this.form.height || undefined,
            VirtualWareHouseId: this.form.warehouse,
            TenantId: this.TenantId,
            ShopId: this.shopID,
          }
          this.loading = true
          computedVolume(data).then(response => {
            this.loading = false
            this.weight = response.Data
          })
        }
      })
    }
  },
  created() {
    this.getData()
    this.$store.dispatch('webSite/getShopMatters', 12).then(data => {
      this.content = data[0].Content
    })
  },
  data() {
    return {
      form: {
        length: undefined,
        width: undefined,
        height: undefined,
        warehouse: undefined,
      },
      content: "",
      warehouseOption: [],
      loading: false,
      weight: 0,
    }
  },
  computed: {
    ...mapGetters(['shopID', 'TenantId']),
    rules () {
      return {
        length: [
          {required: true, message: this.$t('volume.fillLength'), trigger: 'blur'},
        ],
        width: [
          {required: true, message: this.$t('volume.fillWidth'), trigger: 'blur'},
        ],
        height: [
          {required: true, message: this.$t('volume.fillHeight'), trigger: 'blur'},
        ],
        warehouse: [{ required: true, message: this.$t('shippingcalculation.rules.selectWarehouse'), trigger: 'blur' }],
      }
    }
  }
}

</script>

<style scoped lang="scss">
.container {
  padding: 2rem;
  .volume-title {
    font-size: 1.6rem;
    color: #194D8F;
    padding: 1.5rem;
    border-bottom: 0.1rem solid rgba(230,230,230,1);
  }
  .sub-btn {
    background: #194D8F;
    border-radius: 0.2rem;
    color: #ffffff;
    width: 12rem;
    margin-bottom: 1rem;
  }
  .computed-box {
    padding: 2rem;
    .computed-body {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .form-box {
        flex-basis: 48%;
      }
      .box-img {
        flex-basis: 30%;
      }
    }
    .computed-result {
      padding: 1.5rem 0;
      width: 100%;
      text-align: center;
      background: #F6FAFF;
      border: 0.2rem solid rgba(60,152,253,1);
      border-radius: 0.8rem;
      font-size: 2.8rem;
      color: #1154A8;
    }
    .computed-notice {
      font-size: 1.4rem;
      color: #9D9D9D;
    }
  }
}
</style>
