<template>
  <div>
    <p>{{ $t('recharge.inventory.title') }}</p>
    <p>{{ $t('recharge.inventory.balance') }}：{{ userInfo.Money }}</p>
    <p>{{ $t('recharge.inventory.pon') }}：{{ `PON${generateOrderNumber}` }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "rechargeInventory",
  computed: {
    ...mapGetters(['userInfo']),
    generateOrderNumber() {
      const characters = '0123456789';
      let orderNumber = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        orderNumber += characters.charAt(randomIndex);
      }
      return orderNumber;
    }
  },
  methods: {

  }
}
</script>

<style scoped>
p {
  font-size: 1.5rem;
}
</style>
