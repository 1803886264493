<template>
  <div>
    <div class="back" @click="back">
      <el-icon class="el-icon-arrow-left"></el-icon>
    </div>
    <div class="pay-box">
      <div class="describe" v-if="methods === 'onLine'">
        <p>{{ $t('QrCodePay.paymentAmount') }}：{{ order.Receivables }}</p>
        <p>{{ $t('QrCodePay.orderNumber') }}：{{ order.MainBillCode }}</p>
        <p>{{ $t('QrCodePay.orderDescription') }}：{{ describe }}</p>
      </div>
      <div style="font-size: 12px" class="describe" v-else>
        <p v-if="payTypeData.BankDeposit">{{ $t('QrCodePay.bankDeposit') }}：{{ payTypeData.BankDeposit }}</p>
        <p v-if="payTypeData.OpeningName">{{ $t('QrCodePay.openingName') }}：{{ payTypeData.OpeningName }}</p>
        <p v-if="payTypeData.Account">{{ $t('QrCodePay.account') }}：{{ payTypeData.Account }}</p>
      </div>
      <div class="qrcode" v-if="methods === 'onLine'">
        <canvas id="qrcodePayCanvas" ref="qrcodePayCanvas"></canvas>
      </div>
      <div class="qrcode-box" v-if="methods === 'offline' && payTypeData.ImageHostURL">
        <el-image :src="payTypeData.ImageHostURL" class="qrcode-img"></el-image>
      </div>
      <div class="feedback" v-if="methods==='offline'">
        <div class="mb10" style="text-align: center; font-size: 12px; color: #898989">{{ $t('QrCodePay.prompt') }}</div>
        <el-input class="mb10" size="small" style="width: 178px" :placeholder="$t('QrCodePay.transactionNo')" v-model="form.TransactionNo"></el-input>
        <el-upload
            action=""
            class="avatar-uploader mb10"
            :show-file-list="false"
            :http-request="progressAvatar"
            :before-upload="beforeAvatarUpload">
          <img v-if="voucherUrl" :src="voucherUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-input style="width: 178px" size="small" type="textarea" :placeholder="$t('QrCodePay.rem')" v-model="form.Rem"></el-input>
      </div>
    </div>
    <div v-if="methods==='offline'" class="mt20" style="display: flex; justify-content: center" >
      <el-button type="primary" round @click="onSubmit">{{ $t('QrCodePay.onSubmit') }}</el-button>
    </div>
  </div>

</template>

<script>
import {QrCodePayState, uploadImage} from "@/api/system";
import QRCode from "qrcode";
import { offlineRecharge } from "@/api/user";

export default {
  props: {
    order: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },
    computed: {

    },
    methods: {
      type: String,
      default: "onLine"
    },
    payTypeData: {
      type: Object,
      default: () => {}
    },
    describe: {
      type: String,
      default: ""
    },
    orderType: {
      type: String,
      default: "goods"
    },
    qrCodeUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      payState: false,
      interval: undefined,
      voucherUrl: "",
      file: "",
      loading: false,
      form: {
        TransactionNo: ""
      }
    }
  },
  watch: {
    qrCodeUrl(newVal) {
      if (newVal && this.methods === 'onLine') {
        this.generate()
      }
    }
  },
  created() {
    if(this.qrCodeUrl && this.methods === 'onLine') {
      this.generate()
    }
  },
  methods: {
    clearIntervalToParent() {
      clearInterval(this.interval);
    },
    onSubmit() {
      if (!this.form.TransactionNo) {
        this.$message.warning(this.$t('QrCodePay.placeholderTransactionNo'))
        return;
      }
      if (!this.file) {
        this.$message.warning(this.$t('QrCodePay.placeholderOffline'))
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('Path', 'WithdrawalImg');
      this.loading = true
      uploadImage(formData).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          const data = {
            VoucherImage: Data.ServerPath,
            Money: this.payTypeData.Money,
            TransactionNo: this.form.TransactionNo,
            Rem: this.form.Rem,
            OrderNo: this.order.MainBillCode,
            Type: 1,
            SourceType: this.orderType === 'recharge' ? 1 : 6,
            PayTypeId: this.payTypeData.payTypeId
          }
          offlineRecharge(data).then(response => {
            this.loading = false
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.$message.success(this.$t('QrCodePay.successMsg'))
              this.$emit('close')
            } else {
              this.$message.warning(Msg)
            }
          }).catch(() => { this.loading = false })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    progressAvatar() {
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t('common.imageValidate'));
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.voucherUrl = e.target.result;
      };
      this.file = file
      return isLt2M;
    },
    generate() {
      setTimeout(() => {
        this.generateQRCode()
        this.interval =  setInterval(this.checkPaymentStatus, 3000);
      }, 500)
    },
    back() {
      clearInterval(this.interval);
      this.$emit('back')
    },
    checkPaymentStatus() {
      const data = {
        OrderNo: this.order.MainBillCode
      }
      QrCodePayState(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200 && Data) {
          this.payState = true
          if (this.orderType === 'order') {
            this.$emit('close', 'refresh')
          } else {
            this.$emit('close')
          }
        } else {
          console.log(Msg)
        }
      })
    },
    generateQRCode() {
      const canvas = this.$refs.qrcodePayCanvas
      QRCode.toCanvas(canvas, this.qrCodeUrl, (error) => {
        if (error) {
          console.error(error)
        } else {
          console.log('QR code generated successfully')
        }
      })
    },
    payStateHandle() {
      if (this.orderType === 'goods') {
        setTimeout(() => {
          this.$router.push({
            path: '/member/order-domestic'
          })
        }, 500)
      }
      if (this.payState) {
        this.$message.success(this.$t('mergerCard.paySuccess') || "")
      } else {
        if (this.orderType === 'recharge') return;
        this.$message.info(this.$t('mergerCard.payInfo') || "")
      }
    },
  }
}

</script>

<style scoped lang="scss">
.back {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #DCDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  cursor: pointer;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.qrcode-box {
  width: 150px;
  height: 150px;
}
.avatar-uploader {
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
.back:hover {
  border: 1px solid #53EC7B;
  color: #53EC7B;
}
.pay-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
