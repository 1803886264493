<template>
  <div class="container">
    <div class="coupon-nav u-center-flex mb30">
      <button class="cu-btn nav-btn u-flex-col u-row-center u-col-center pointer"
              :class="{ 'btn-active': nav.id == stateCurrent }" v-for="nav in couponsState" :key="nav.id"
              @click="onNav(nav)">
        <span class="item-title">{{ nav.title }}</span>
      </button>
    </div>
    <div v-if="stateCurrent===1">
      <video-player
          ref="videoPlayer"
          class="player-video"
          :playsinline="false"
          :options="playOptions"
      />
    </div>
    <div class="grid-box" v-if="stateCurrent===2">
      <div class="grid-item" v-for="(item, i) in images" :key="item.Id">
        <el-image class="grid-img pointer" :preview-src-list="[item.ImgHostURL]" fit="cover" :src="item.ImgHostURL">
          <div slot="placeholder" class="image-slot">
            {{ $t('teach.loading') }}<span class="dot">...</span>
          </div>
        </el-image>
        <div class="u-start-flex">
          <div class="grid-badge">{{ i + 1}}</div>
          <div style="font-size: 2rem">{{ item.Title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getShopVideo } from "@/api/system";
export default {
  data() {
    return {
      stateCurrent: 1,
      images: [],
      playOptions: {
        height: "400px",
        width: "100%",
        playbackRates: [1.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放
        muted: false, // 默认情况下静音播放
        loop: false, // 是否视频一结束就重新开始
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据，auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值，值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小，换句话说，它将按比例缩放以适应其容器
        sources: [],
        poster: "@/static/images/index/core-box/2.jpg", // 设置封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息
        controlBar: {
          currentTimeDisplay: true,
          progressControl: true,  // 是否显示进度条
          playbackRateMenuButton: true, // 是否显示调整播放倍速按钮
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapGetters(['shopID', 'TenantId']),
    couponsState() {
      return [
        {
          id: 1,
          type: 'up-to-date',
          title: this.$t('teach.title1')
        },
        {
          id: 2,
          type: 'preferential',
          title: this.$t('teach.title2')
        }
      ]
    }
  },
  methods: {
    onNav(nav) {
      this.stateCurrent = nav.id;
      this.getData()
    },
    getData() {
      if (this.stateCurrent === 2) {
        this.$store.dispatch('webSite/carouselInit', 6).then(data => {
          this.images = data
        })
      } else {
        const data = {
          PageIndex: 1,
          PageRows: 10,
          ShopId: this.shopID,
          Type: 1,
          TenantId: this.TenantId
        }
        getShopVideo(data).then(response => {
          const { Code, Msg, Data } = response
          if (Code === 200) {
            this.playOptions.sources = [{
              type: "video/mp4",
              src: Data[0].VideoHostURL,
            }]
            this.$forceUpdate()
          } else {
            this.$message.warning(Msg)
          }
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 2rem 2.5rem;
  .coupon-nav {
    background: #fff;
    height: 5rem;
    padding: 0 1.5rem;

    .nav-btn {
      margin-right: 1rem;
      color: #373737;
      width: 20%;
      height: 3.6rem;
      background-color: #fff;
      border-radius: 1.8rem;
      border: 0.1rem solid rgba(151,151,151,1);
    }

    .btn-active {
      color: #fff;
      background-color: #194D8F;
      border: none;
    }
  }
  .grid-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* 创建两列，每列宽度相等 */
    grid-gap: 3rem; /* 设置网格项之间的间距 */
    padding: 1.5rem;
    .grid-item {
      .grid-img {
        margin-bottom: 1rem;
        height: 20rem;
      }
      .grid-badge {
        border-radius: 50%;
        background: #194D8F;
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
        text-align: center;
        align-items: center;
        color: #ffffff;
        margin-right: 1rem;
      }
    }
  }
}
</style>
