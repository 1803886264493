<template>
  <div class="u-start-flex" style="background-color: #C6E1FF">
    <el-image :src="require('@/static/images/member/notice.png')" class="notice-img"></el-image>
<!--    <i class="el-icon-chat-line-round" style="font-size: 20px"></i>-->
    <div class="notice">
      <div class="notice__inner">
        <el-carousel height="40px" direction="vertical" :interval="4000" autoplay indicator-position="none">
          <el-carousel-item v-for="item in notices" :key="item.Id">
            <div class="notice__item" @click="jumpDetail(item.Id)">{{ item.Name }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {getNotices} from "@/api/system";
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      notices: []
    }
  },
  computed: {
    ...mapGetters(['shopID'])
  },
  created() {
    this.getNotices()
  },
  methods: {
    jumpDetail(rowId) {
      let routeUrl = this.$router.resolve({
        path: "/news-detail",
        query: { id: rowId }
      });
      window.open(routeUrl.href, '_blank');
    },
    getNotices() {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        ShopId: this.shopID,
      }
      getNotices(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.notices = Data;
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
.notice-img {
  width: 20px;
  padding-left: 8px;
}
.notice{
  width: 600px;
  height: 30px;
  overflow: hidden;
}
.notice__inner{
  animation: roll 36s linear infinite;
  margin-top: 0;
}
.notice__item{
  color: #194D8F;
  cursor: pointer;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  white-space: nowrap;
  text-decoration: underline;
}
</style>
