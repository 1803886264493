<template>
  <el-dialog :before-close="close" :title="$t('recharge.title')" width="35%" :visible.sync="show">
    <div class="body">
      <div class="balance">
        <el-image :src="require('@/static/images/member/recharge/money.png')" class="balance-icon"></el-image>
        <div>{{ $t('recharge.balance') }}:{{ userInfo.Money }}</div>
      </div>
      <div class="options-box">
        <div class="head">{{ $t('recharge.money')  }}</div>
        <div class="grid-box">
          <div class="recharge-btn pointer" @click="onNav(number)" :class="{ 'btn-active': number == stateCurrent }" v-for="number in options" :key="number">
            ￥{{ number }}
          </div>
          <el-input :class="{ 'input-active': inputSelected === true }" type="number" @focus="handleFocus" placeholder="自定义金额" v-model="rechargeInput"></el-input>
        </div>
        <div class="bottom">{{ `￥${ $t('recharge.confirmMoney') }：${ showRecharge || 0 }` }}</div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button style="width: 40%" round v-loading="loading" :disabled="disabled" type="primary" @click="recharge">{{ $t('recharge.submit') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "RechargeBox",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      stateCurrent: 0,
      rechargeInput: undefined,
      options: [100,200,500,800,1000,2000,5000],
      inputSelected: false
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    disabled() {
      return !(this.stateCurrent > 0 || this.rechargeInput > 0)
    },
    showRecharge() {
      return this.stateCurrent || this.rechargeInput
    }
  },
  methods: {
    recharge() {
      if (!this.showRecharge) {
        this.$message.warning(this.$t('recharge.warning'))
      }
      this.$emit('handle', this.showRecharge)
    },
    close() {
      this.$emit('close')
    },
    onNav(number) {
      this.stateCurrent = number;
      this.rechargeInput = undefined
      this.inputSelected = false
    },
    handleFocus() {
      this.inputSelected = true
      this.stateCurrent = 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 1.7rem;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .input-active {
  .el-input__inner {
    background-color: #FFEAEF !important;
    border: 1px solid #FE2854 !important;
    height: 3rem;
    border-radius: 1.5rem;
    text-align: center;
  }
}
::v-deep .el-input__inner {
  height: 3rem;
  border-radius: 1.5rem;
  text-align: center;
  border: 1px solid #C4B7B7;
  color: #C4B7B7;
  font-size: 1.5rem;
}
.body {
  padding: 1rem;
  .balance {
    width: 18rem;
    height: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2rem;
    padding-left: 1.2rem;
    color: #A49898;
    font-size: 1.5rem;
    background-color: #F7F0F0;
    .balance-icon {
      width: 2.5rem;
      margin-right: 0.8rem;
    }
  }
  .grid-box {
    display: grid;
    margin: 2rem 0;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    .btn-active {
      background-color: #2A82E4;
      color: #ffffff !important;
      border: none !important;
    }
    .recharge-btn {
      height: 3rem;
      border-radius: 1.5rem;
      border: 1px solid #C4B7B7;
      color: #C4B7B7;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .options-box {
    .head {
      margin: 1rem 0;
      font-size: 1.4rem;
    }
    .bottom {
      color: #818181;
      font-size: 1.5rem;
    }
  }
}
</style>
