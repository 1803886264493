<template>
  <div class="added-container">
    <div class="query-box">
      <div class="u-start-flex" style="align-items: flex-start">
        <el-input
            class="mr8"
            size="small"
            clearable
            type="number"
            @clear="handleClick"
            :placeholder="$t('added.placeholderCode')"
            style="width: 200px" v-model="Codes"></el-input>
        <el-select size="small" class="mr8" v-model="type" style="width: 100px" :placeholder="$t('added.type')">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select size="small" class="mr8" v-model="PayState" style="width: 120px" clearable :placeholder="$t('added.PayState')" @clear="handleClick">
          <el-option
              v-for="item in payStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select class="mr15" size="small" v-model="IsHandle" style="width: 120px" clearable :placeholder="$t('added.IsHandle')" @clear="handleClick">
          <el-option
              v-for="item in handleOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button size="small" type="primary" @click="handleClick">{{ $t('added.search') }}</el-button>
      </div>
    </div>
    <el-table v-loading="loading" :height="pageSize > 10 ? tableHeight : ''" :max-height="tableHeight" :data="listData" size="mini" border style="width: 100%">
      <el-table-column prop="OrderId" :label="$t('added.OrderId')"></el-table-column>
      <el-table-column prop="Code" width="180" :label="$t('added.Code')"></el-table-column>
      <el-table-column prop="AddedServiceName" :label="$t('added.name')"></el-table-column>
      <el-table-column prop="Price" :label="$t('added.Price')"></el-table-column>
      <el-table-column prop="TypeName" :label="$t('added.TypeTxt')"></el-table-column>
      <el-table-column prop="PayState" :label="$t('added.PayState')">
        <template slot-scope="scope">
          <span>{{ formatState(scope.row.PayState) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="PayTime" width="160" :label="$t('added.PayTime')">
        <template slot-scope="scope">
          <span>{{ scope.row.PayTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="IsHandle" :label="$t('added.IsHandle')">
        <template slot-scope="scope">
          <span>{{ formatHandle(scope.row.IsHandle) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="HandleTime" width="160" :label="$t('added.HandleTime')">
        <template slot-scope="scope">
          <span>{{ scope.row.HandleTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="Rem" :label="$t('added.Rem')"></el-table-column>
      <el-table-column prop="CreateTime" width="160" :label="$t('added.CreateTime')">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getAddedList } from "@/api/system";
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      listData: [],
      total: 0,
      IsHandle: "",
      PayState: "",
      QueryCodeType: 3,
      pageIndex: 1,
      Codes: "",
      pageSize: 10,
      type: 1,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'shopID']),
    tableHeight() {
      return 'calc(100vh - 16rem - 99px)'
    },
    options() {
      return [
        {
          label: this.$t('added.order'),
          value: 1
        },
        {
          label: this.$t('added.package'),
          value: 2
        },
      ]
    },
    queryOptions() {
      return [
        {
          label: this.$t('added.orderCode'),
          value: 3
        },
        {
          label: this.$t('added.billCode'),
          value: 7
        },
      ]
    },
    payStateOptions() {
      return [
        {
          label: this.$t('added.paid'),
          value: 2
        },
        {
          label: this.$t('added.unpaid'),
          value: 1
        }
      ]
    },
    handleOption() {
      return [
        {
          label: this.$t('added.handle'),
          value: 2
        },
        {
          label: this.$t('added.Unprocessed'),
          value: 1
        }
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.pageIndex = 1;
      this.getData()
    },
    formatState(val) {
      if (val) {
        return this.$t('added.paid')
      } else {
        return this.$t('added.unpaid')
      }
    },
    handleChange(val) {
      this.pageIndex = val
      this.getData()
    },
    handleClick() {
      this.pageIndex = 1;
      this.getData()
    },
    formatHandle(val) {
      if (val) {
        return this.$t('added.handle')
      } else {
        return this.$t('added.Unprocessed')
      }
    },
    getData() {
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
        ClientId: this.userInfo.Id,
        OrderId: this.Codes || undefined,
        ShopId: this.shopID,
        QueryCodeType: this.QueryCodeType,
        Type: this.type || undefined,
        PayState: this.PayState || undefined,
        IsHandle: this.IsHandle || undefined
      }
      this.loading = true
      getAddedList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      }).catch(() => { this.loading = false })
    }
  }
}
</script>

<style scoped lang="scss">
.added-container {
  padding: 2rem 2.5rem;
  .query-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
}
</style>