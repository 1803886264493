<template>
  <div class="layout">
    <div class="navigation">
      <div class="navigation-logo">
        <el-image style="width: 5rem" :src="require('@/static/images/member/huochuan.png')" fit="cover"></el-image>
        <div class="navigation-title">{{ $t('noticelayout.title') }}</div>
      </div>
      <el-menu
          :default-active="activeMenu"
          active-text-color="#194D8F"
          router
          class="el-menu-vertical-demo">
        <el-menu-item :index="item.index" v-for="item in menuItems" :key="item.id">
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="operate">
      <transition name="custom-transition" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  // 在你的 Vue 组件中
  data() {
    return {
    };
  },

  computed: {
    activeMenu() {
      const route = this.$route
      const { path } = route
      return path
    },
    menuItems() {
      return [
        {
          id: 1,
          title: this.$t('layout.consolidation.tutorial'),
          index: '/consolidation/teach'
        },
        {
          id: 2,
          title: this.$t('layout.consolidation.shippingCalculation'),
          index: '/consolidation/shipping-calculation'
        },
        {
          id: 3,
          title: this.$t('layout.consolidation.volumeConversion'),
          index: '/consolidation/volume-conversion'
        },
        {
          id: 4,
          title: this.$t('layout.consolidation.precautions'),
          index: '/consolidation/precautions'
        },
        {
          id: 5,
          title: this.$t('layout.consolidation.faq'),
          index: '/consolidation/problem'
        },
        {
          id: 7,
          title: this.$t('layout.consolidation.prohibitedItems'),
          index: '/consolidation/contraband'
        }
      ]
    }
  },
  components: {
  },
  created() {
    console.log(this.activeMenu)
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
.custom-transition-enter-active, .custom-transition-leave-active {
  transition: all 0.5s;
}
.custom-transition-enter, .custom-transition-leave-to /* .custom-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}
.layout {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .navigation {
    width: 21%;
    height: auto;
    .navigation-logo {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: #fff;
      padding: 1.8rem 1.2rem;
      border: 0.1rem solid rgba(237,237,237,1);
      .navigation-title {
        font-size: 2.4rem;
        font-weight: bold;
        color: #194D8F;
      }
    }
  }
  .operate {
    background-color: #fff;
    width: 77%;
    min-height: calc(100vh - 39rem);
  }
}
</style>
