<template>
  <div class="container">
<!--    <el-tabs v-model="activeName" @tab-click="handleClick">-->
<!--      <el-tab-pane :label="$t('problem.domestic')" name="domestic">-->
<!--        <div class="pane-box">-->
          <el-collapse class="mb10" accordion v-for="(collapse, index) in collapses" :key="collapse.Id">
            <el-collapse-item>
              <template slot="title">
                <div class="u-start-flex">
                  <div class="tag-box mr30">{{ index + 1 }}</div>
                  <div class="default-font">{{ collapse.Title }}</div>
                </div>
              </template>
              <div v-html="collapse.Content"></div>
            </el-collapse-item>
          </el-collapse>
<!--        </div>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="false" :label="$t('problem.foreign')" name="foreign">-->
<!--        <div class="pane-box">-->
<!--          <div class="coupon-nav u-center-flex mb10">-->
<!--            <button class="cu-btn nav-btn u-flex-col u-row-center u-col-center"-->
<!--                    :class="{ 'btn-active': nav.id == stateCurrent }" v-for="nav in couponsState" :key="nav.id"-->
<!--                    @click="onNav(nav)">-->
<!--              <span class="item-title">{{ nav.title }}</span>-->
<!--            </button>-->
<!--          </div>-->
<!--          <el-collapse class="mb10" accordion>-->
<!--            <el-collapse-item>-->
<!--              <template slot="title">-->
<!--                <div class="u-start-flex">-->
<!--                  <div class="tag-box mr30">1</div>-->
<!--                  <div class="default-font">是否提供去除鞋盒, 大體積包裝等服務?</div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>-->
<!--              <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>-->
<!--            </el-collapse-item>-->
<!--          </el-collapse>-->
<!--          <el-collapse class="mb10" accordion>-->
<!--            <el-collapse-item>-->
<!--              <template slot="title">-->
<!--                <div class="u-start-flex">-->
<!--                  <div class="tag-box mr30">1</div>-->
<!--                  <div class="default-font">是否提供去除鞋盒, 大體積包裝等服務?</div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>-->
<!--              <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>-->
<!--            </el-collapse-item>-->
<!--          </el-collapse>-->
<!--          <el-collapse class="mb10" accordion>-->
<!--            <el-collapse-item>-->
<!--              <template slot="title">-->
<!--                <div class="u-start-flex">-->
<!--                  <div class="tag-box mr30">1</div>-->
<!--                  <div class="default-font">是否提供去除鞋盒, 大體積包裝等服務?</div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>-->
<!--              <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>-->
<!--            </el-collapse-item>-->
<!--          </el-collapse>-->
<!--        </div>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'domestic',
      stateCurrent: undefined,
      collapses: [],
      couponsState: [
        {
          id: 1,
          type: 1,
          title: '海外簽收、退貨問題'
        },
        {
          id: 2,
          type: 1,
          title: '轉運及集運問題'
        },
        {
          id: 3,
          type: 1,
          title: '美國購物問題'
        }
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('webSite/getShopMatters', 1).then(response => {
        this.collapses = response
      })
    },
    handleClick() {},
    onNav(nav) {
      this.stateCurrent = nav.id
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-collapse-item__header {
  background-color: #F7F8FA;
  padding-left: 1.5rem;
}
::v-deep .el-collapse-item__content {
  background-color: #F7F8FA;
}
.container {
  padding: 2rem 2.5rem;
  .pane-box {
    .tag-box {
      width: 2rem;
      height: 2rem;
      background-color: #194D8F;
      border-radius: 50%;
      color: #fff;
      line-height: 2rem;
      text-align: center;
    }
    .coupon-nav {
      background: #fff;
      height: 5rem;
      padding: 0 1.5rem;

      .nav-btn {
        margin-right: 1rem;
        color: #373737;
        width: 20%;
        height: 3.6rem;
        background-color: #fff;
        border-radius: 1.8rem;
        border: 0.1rem solid rgba(151,151,151,1);
      }

      .btn-active {
        color: #fff;
        background-color: #194D8F;
        border: none;
      }
    }
  }
}
</style>
