<template>
  <div class="flow-container">
    <div class="query-box">
      <div class="show-box">
        <div class="u-between-flex">
          <div class="item">
            <div>
              <div style="padding: 0 4px" class="mb10">{{ $t('flow.title') }}</div>
              <div>
                <el-statistic
                    group-separator=","
                    :value-style="{color: '#AE9FFB', fontWeight: 550}"
                    :precision="2"
                    :value="statistics.ConsumptionTotalAmount"
                ></el-statistic>
              </div>
            </div>
            <el-image :src="require('@/static/images/member/flow/statistics.png')" class="item-img"></el-image>
          </div>
          <div class="item">
            <div>
              <div style="padding: 0 4px" class="mb10">{{ $t('flow.residue') }}</div>
              <div>
                <el-statistic
                    group-separator=","
                    :value-style="{color: '#E2637A', fontWeight: 550}"
                    :precision="2"
                    :value="statistics.SurplusAvailable"
                ></el-statistic>
              </div>
            </div>
            <el-image :src="require('@/static/images/member/flow/money.png')" class="item-img"></el-image>
          </div>
          <div class="item">
            <div>
              <div style="padding: 0 4px" class="mb10">{{ $t('flow.thisWeek') }}</div>
              <div>
                <el-statistic
                    group-separator=","
                    :value-style="{color: '#3AD6F1', fontWeight: 550}"
                    :precision="2"
                    :value="statistics.CurrentConsumption"
                ></el-statistic>
              </div>
            </div>
            <el-image :src="require('@/static/images/member/flow/forms.png')" class="item-img"></el-image>
          </div>
          <div class="item">
            <div>
              <div style="padding: 0 4px" class="mb10">{{ $t('flow.today') }}</div>
              <div>
                <el-statistic
                    group-separator=","
                    :value-style="{color: '#82A7FE', fontWeight: 550}"
                    :precision="2"
                    :value="statistics.ConsumptionToday"
                ></el-statistic>
              </div>
            </div>
            <el-image :src="require('@/static/images/member/flow/count.png')" class="item-img"></el-image>
          </div>
        </div>
      </div>
      <div class="query">
        <el-select size="small" v-model="query.WalletLogType" :placeholder="$t('flow.placeholderWalletLogType')" style="width: 20rem">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-input size="small" v-model="query.TransactionNo" @clear="getData" clearable :placeholder="$t('flow.placeholderTransactionNo')" style="width: 240px"></el-input>
        <el-button size="small" type="primary" @click="getData">{{ $t('flow.search') }}</el-button>
      </div>
    </div>
    <el-table :data="flows" style="width: 100%; min-height: 50rem" v-loading="loading" border>
      <el-table-column prop="OrderNo" :label="$t('flow.OrderNo')" width="200"></el-table-column>
      <el-table-column prop="TransactionNo" :label="$t('flow.TransactionNo')" width="240"></el-table-column>
      <el-table-column prop="Rem" show-overflow-tooltip :label="$t('flow.Rem')" width="200"></el-table-column>
      <el-table-column prop="TypeName" :label="$t('flow.TypeName')"></el-table-column>
      <el-table-column prop="CreateTime" :label="$t('flow.CreateTime')" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="VariableState" :label="$t('flow.VariableState')"></el-table-column>
      <el-table-column prop="Money" :label="$t('flow.Money')"></el-table-column>
      <el-table-column prop="OldMoney" :label="$t('flow.OldMoney')"></el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        layout="prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageIndex"
        @current-change="handleChange"
        hide-on-single-page
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {getWalletFlow} from "@/api/system";
export default {
  name: "index",
  data() {
    return {
      query: {
        TransactionNo: undefined,
        WalletLogType: 1
      },
      flows: [],
      statistics: {},
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    options() {
      return  [
        {
          label: this.$t('flow.Balance'),
          value: 1
        },
        {
          label: this.$t('flow.Integral'),
          value: 2
        }
      ]
    }
  },
  methods: {
    handleChange(current) {
      this.pageIndex = current;
      this.getData()
    },
    getData() {
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
        ClientId: this.userInfo.Id,
        Type: this.query.WalletLogType || undefined,
        TransactionNo: this.query.TransactionNo
      }
      this.loading = true
      getWalletFlow(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          const { ClientWalletLogInfo, ConsumptionToday, ConsumptionTotalAmount, CurrentConsumption, SurplusAvailable } = Data
          this.statistics = {
            ConsumptionToday,
            ConsumptionTotalAmount,
            CurrentConsumption,
            SurplusAvailable
          }
          this.flows = ClientWalletLogInfo;
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.flow-container {
  padding: 2rem 2.5rem;
  position: relative;
  .query-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    .show-box {
      width: 60%;
      .item {
        margin: 0 1rem 1rem 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 这里的rgba(0, 0, 0, 0.1)表示黑色阴影，最后一位0.1表示透明度 */
        border-radius: 1.5rem;
        border: 1px solid #A6A8A8;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 20rem;
        .item-img {
          width: 3rem;
        }
      }
    }
    .query {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
