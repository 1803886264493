<template>
  <div class="added-container">
    <div class="query-box">
      <div class="u-start-flex">
        <el-select size="small" class="mr8" v-model="type" style="width: 240px" clearable :placeholder="$t('added.type')" @clear="handleClick">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-input size="small" class="mr15" :placeholder="$t('added.name')" clearable style="width: 240px" v-model="name" @clear="handleClick"></el-input>
        <el-button size="small" type="primary" @click="handleClick">{{ $t('added.search') }}</el-button>
      </div>
    </div>
    <el-table
        :data="listData"
        border
        size="mini"
        v-loading="loading"
        style="width: 100%">
      <el-table-column
          prop="Name"
          :label="$t('added.name')">
      </el-table-column>
      <el-table-column
          prop="TypeTxt"
          :label="$t('added.TypeTxt')">
      </el-table-column>
      <el-table-column
          prop="Price"
          :label="$t('added.Price')">
      </el-table-column>
      <el-table-column
          prop="Rem"
          :label="$t('added.Rem')">
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        layout="prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageIndex"
        @current-change="handleChange"
        hide-on-single-page
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {getAdded} from "@/api/system";

export default {
  data() {
    return {
      listData: [],
      total: 0,
      name: "",
      pageIndex: 1,
      pageSize: 10,
      type: undefined,
      loading: false
    }
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('added.order'),
          value: 1
        },
        {
          label: this.$t('added.package'),
          value: 2
        },
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleChange(val) {
      this.pageIndex = val
      this.getData()
    },
    handleClick() {
      this.pageIndex = 1;
      this.getData()
    },
    getData() {
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
        Type: this.type || undefined,
        Name: this.name || undefined
      }
      this.loading = true
      getAdded(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      }).catch(() => { this.loading = false })
    }
  }
}
</script>

<style scoped lang="scss">
.added-container {
  padding: 2rem 2.5rem;
  .query-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
}
</style>