<template>
  <div class="coupon-container">
    <el-tabs :stretch="true">
      <el-tab-pane>
        <div slot="label">
          <div class="u-center-flex tab-panel">
            <el-image :src="require('@/static/images/member/coupon/table-1.png')" class="tab-img mr30"></el-image>
            <div>{{ $t('coupon.pointscashroll') }}</div>
          </div>
        </div>
        <div class="pane-box u-between-flex" style="flex-wrap: wrap">
          <div class="inter-box" v-for="item in listData" :key="item.Id">
            <div class="left-box">
              <div style="color: #f86c0a; font-size: 24px">￥<span style="font-size: 40px; font-weight: 550">{{ item.Formula }}</span></div>
              <div>{{ $t('coupon.periodOfValidity') }}:{{ $t('coupon.notice', [item.ExpirationDays]) }}</div>
              <div style="font-size: 12px">
                <span v-if="item.MinPrice" style="margin-right: 10px">{{ $t('coupon.minPrice') }}: {{ item.MinPrice }}</span>
                <span v-if="item.MinWeight">{{ $t('coupon.MinWeight') }}: {{ item.MinWeight }}</span>
              </div>
<!--              <div>{{ $t('coupon.periodOfValidity') }}:{{ item.CouponStartTime | formatDate }}{{ $t('coupon.to') }}{{ item.ExpiredTime | formatDate }}</div>-->
            </div>
            <div class="right-box">
              <div class="mb10" style="font-size: 22px; color: #353535">{{ item.Name }}</div>
              <div class="mb10" style="font-size: 16px; color: #353535">{{ item.Rem }}</div>
              <div class="mb10" style="color: #ff6c05; font-size: 22px">{{ item.Integral }}{{ $t('coupon.integral') }}</div>
              <button class="exchange-btn" @click="exchange(item.Id)">{{ $t('coupon.exchange') }}</button>
            </div>
          </div>
          <el-empty v-if="!listData.length" style="margin: 10rem auto 0 auto" description="没有数据"></el-empty>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <div slot="label">
          <div class="u-center-flex tab-panel">
            <el-image :src="require('@/static/images/member/coupon/table-2.png')" class="tab-img mr30"></el-image>
            <div>{{ $t('coupon.discountcoupon') }}</div>
          </div>
        </div>
        <div class="pane-box">
          <coupon-tab :coupons="coupons"></coupon-tab>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import couponTab from "@/views/member/coupon/components/coupon-tab.vue";
import { getCoupon } from "@/api/member";
import { mapGetters } from "vuex"
import {getShopEvents} from "@/api/system";
import moment from "moment";
import {exchangeCoupon} from "@/api/user";
export default {
  data() {
    return {
      integral: undefined,
      coupons: [],
      listData: []
    }
  },
  components: {
    couponTab
  },
  filters: {
    formatDate(val) {
      return moment(val).format("YYYY-MM-DD")
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'shopID'])
  },
  methods: {
    exchange(id) {
      this.$confirm(this.$t('coupon.title'), this.$t('cashier.prompt'), {
        confirmButtonText: this.$t('cashier.confirm'),
        cancelButtonText: this.$t('cashier.cancel'),
        type: 'warning'
      }).then(() => {
        const data = {
          Id: id,
          ShopId: this.shopID
        }
        exchangeCoupon(data).then(response => {
          if (response.Code === 200) {
            this.$message.success(this.$t('coupon.exchangeSuccess'))
            this.$store.dispatch('user/getInfo')
            this.getCoupon()
            this.getExchange()
          } else {
            this.$message.warning(response.Msg)
          }
        })
      })
    },
    getCoupon() {
      const data = {
        pageIndex: 1,
        pageRows: 100,
        clientId: this.$store.state.user.userInfo.Id
      }
      getCoupon(data).then(response => {
        this.coupons = response.Data;
      })
    },
    getExchange() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
        GiveType: 7,
        IsEffective: 2,
        IsNumberAttend: 2,
        ShopId: this.shopID
      }
      getShopEvents(data).then(res => {
        this.listData = res.Data
      })
    }
  },
  created() {
    this.getCoupon()
    this.getExchange()
  }
}

</script>

<style scoped lang="scss">

::v-deep #tab-0,#tab-1 {
  height: 8rem;
}

.media-font {
  font-size: 1.4rem;
  color: #fff;
}

.title-font {
  font-size: 3.6rem;
  font-weight: 500;
  color: #fff;
}
.coupon-container {
  .tab-panel {
    height: 8rem;
    .tab-img {
      width: 4.8rem;
    }
  }
  .pane-box {
    padding: 2rem 2.5rem;
    .inter-box {
      display: flex;
      align-items: center;
      width: 50%;
      height: 150px;
      margin-bottom: 10rem;
      .left-box {
        background: #ffe7d6;
        color: #c3afa1;
        font-size: 14px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2rem;
      }
      .right-box {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background: #fef6f0;
        height: 100%;
        .exchange-btn {
          width: 80px;
          height: 32px;
          font-size: 14px;
          line-height: 32px;
          border: none;
          text-align: center;
          color: #fff;
          background: #fd931c;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .integral {
      width: 100%;
      background: #FAFCFF;
      .title-integral {
        font-family: PingFangSC-Regular;
        font-size: 2rem;
        color: #194D8F;
        font-weight: 400;
        text-align: center;
      }
      .integral-input {
        width: 40%;
      }
      .integral-img {
        width: 2.5rem;
      }
      .integral-box {
        width: 40%;
        background-color: #F88A4D;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        color: #ffffff;
        height: 4rem;
        line-height: 4rem;
        padding-left: 2.5rem;
      }
      .exchange {
        width: 100%;
        text-align: center;
        .exchange-btn {
          width: 16rem;
          color: #fff;
          background: #194D8F;
          border-radius: 0.8rem;
          margin: 2rem 0;
        }
      }
    }
    .coupon-box {
      position: relative;
      flex-basis: 23%;
      height: 27rem; /* 根据需要设置矩形的高度 */
      border: 0.1rem solid #D8D8D8;
      border-radius: 0.5rem;
      display: inline-block;
      .coupon-header-box {
        height: 11rem;
        width: 100%;
        background-color: #FF7D35;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 0.5rem;
      }
      .coupon-introduce-box {
        width: 100%;
        height: 11rem;
        border-bottom: 0.1rem solid #D8D8D8;
        font-size: 1rem;
        color: #333333;
      }
    }

    .coupon-box::before,
    .coupon-box::after {
      content: "";
      position: absolute;
      top: 11rem;
      width: 2rem; /* 根据需要设置凹槽的宽度 */
      height: 2rem; /* 半圆的高度等于矩形的高度 */
      background-color: #FFF; /* 与矩形的背景颜色相同 */
      border-radius: 50%; /* 使元素呈现半圆形状 */
      box-sizing: border-box; /* 调整盒模型，确保边框在元素内部 */
      overflow: hidden;
    }

    .coupon-box::before {
      left: 0;
      transform-origin: right; /* 设置变换的基准点为右侧边缘 */
      transform: translate(-50%, -50%); /* 将元素垂直居中对齐 */
    }

    .coupon-box::after {
      right: 0;
      transform-origin: left; /* 设置变换的基准点为左侧边缘 */
      transform: translate(50%, -50%); /* 将元素垂直居中对齐 */
    }
    }
}



</style>
