<template>
  <div class="container">
    <div class="standard-title mb30 mt20">{{ $t('account.title') }}</div>
    <table>
      <tr v-for="(item, index) in listData" :key="index">
        <td v-for="column in item" :key="column.id" style="width: 25%">
          <div class="u-between-flex medium-box" style="font-size: 1.6rem">
            <div>{{ column.title }}</div>
            <div v-if="column.field === 'Sex'">{{ parseSex(userInfo[column.field]) }}</div>
            <div v-else-if="column.field === 'avatar'">
              <el-avatar :size="24" :src="userInfo.HostPortrait"></el-avatar>
            </div>
            <div v-else>{{ !column.type ? userInfo[column.field] : '******' }}</div>
            <el-image :src="require(`@/static/images/member/account/${column.icon}`)" @click="processAccount(column)" fit="cover" class="icon-img pointer"></el-image>
          </div>
        </td>
      </tr>
    </table>
    <div class="standard-title mt20 mb30">{{ $t('account.accountsecurity') }}</div>
    <div class="safe-account">
      <div class="u-start-flex mb30">
        <div class="u-start-flex safe-box pointer">
          <el-image :src="require('@/static/images/member/account/safe.png')" class="safe-image mr30 ml30"></el-image>
          <div>{{ $t('account.mailbox') }}</div>
        </div>
        <div class="u-start-flex safe-box pointer" @click="LogoffAccount">
          <el-image :src="require('@/static/images/member/account/safe.png')" class="safe-image mr30 ml30"></el-image>
          <div>{{ $t('account.cancellation') }}</div>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('account.editaccount')" width="25%" :visible.sync="EditAccountVisible">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-position="left" label-width="80px">
        <el-form-item :label="$t('account.name')" prop="Name">
          <el-input disabled v-model="form.Name" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('account.sex')" prop="Sex">
          <el-select v-model="form.Sex" size="small" :placeholder="$t('account.pleaseselect')" style="width: 30rem">
            <el-option :label="$t('account.secrecy')" :value="3"></el-option>
            <el-option :label="$t('account.male')" :value="2"></el-option>
            <el-option :label="$t('account.female')" :value="1"></el-option>
            <el-option :label="$t('account.unlimited')" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('account.email')" prop="Email">
          <el-input v-model="form.Email" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <div class="u-start-flex" style="width: 32rem;">
          <el-form-item :label="$t('account.phone')" prop="Tel">
            <el-select v-model="form.Tel" size="small" style="width: 10rem">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="PhoneNumber" label-width="5px">
            <el-input v-model="form.PhoneNumber" size="small" style="width: 19rem" :placeholder="$t('account.phonenumber')"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button v-loading="loading" type="primary" @click="submitForm('ruleForm')">{{$t('account.confirmupdate')}}</el-button>
        <el-button @click="EditAccountVisible = false">{{$t('account.cancel')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('account.password')" width="25%" :visible.sync="PasswordVisible">
      <el-form :model="ruleForm" :rules="PwdRules" ref="PwdRuleForm" label-position="left" label-width="80px">
        <el-form-item :label="$t('account.oldpassword')" prop="oldPassword">
          <el-input v-model="ruleForm.oldPassword" show-password size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('account.newpassword')" prop="newPassword">
          <el-input v-model="ruleForm.newPassword" show-password size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('account.confirmpassword')" prop="confirmPassword">
          <el-input v-model="ruleForm.confirmPassword" show-password size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button v-loading="loading" type="primary" @click="submitForm('PwdRuleForm')">{{$t('account.confirmupdate')}}</el-button>
        <el-button @click="PasswordVisible = false">{{$t('account.cancel')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('account.editAvatar')" width="280px" :visible.sync="avatarVisible">
      <div style="display: flex; justify-content: center; align-items: center">
        <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :http-request="progressAvatar"
            :before-upload="beforeAvatarUpload">
          <img v-if="avatarUrl" :src="avatarUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button v-loading="loading" type="primary" @click="editAvatar">{{$t('account.confirmupdate')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import clipboardCopy from "clipboard-copy";
import {CountryInfo, uploadImage} from "@/api/system";
import {LogoffUser, updateAvatar, UpdatePwd, UpdateUser} from "@/api/user";

export default {
  computed: {
    ...mapGetters(["userInfo"]),
    logOffTime() {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 7);
      return currentDate
    },
    PwdRules() {
      return {
        oldPassword: [
          { required: true, message: this.$t('account.rules.enterOldPassword'), trigger: 'blur' },
          { min: 6, message: this.$t('account.rules.passwordLength'), trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: this.$t('account.rules.enterNewPassword'), trigger: 'blur' },
          { min: 6, message: this.$t('account.rules.passwordLength'), trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: this.$t('account.rules.passwordMismatch'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.ruleForm.newPassword) {
                callback(new Error(this.$t('account.rules.passwordMismatch')));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      }
    } ,
    rules() {
      return {
        Name: [
          { required: true, message: this.$t('account.rules.enterUserName'), trigger: 'blur' },
        ],
        Sex: [
          { required: true, message: this.$t('account.rules.selectGender'), trigger: 'blur' }
        ],
        Email: [
          { required: true, message: this.$t('account.rules.enterEmail'), trigger: 'blur' }
        ],
        PhoneNumber: [
          { required: true, message: this.$t('account.rules.enterPhoneNumber'), trigger: 'blur' },
          { validator: this.validatePhoneNum, trigger: 'blur' }
        ]
      }
    } ,
    listData() {
      return [
        [
          {
            id: 1,
            title: this.$t("account.listData.avatar"),
            field: 'avatar',
            icon: 'xiugai.png',
            operate: 'avatar'
          },
          {
            id: 2,
            title: this.$t("account.listData.memberNumber"),
            field: 'Id',
            icon: 'fuzhi.png',
            operate: 'copy'
          },
          {
            id: 4,
            title: this.$t("account.listData.userName"),
            field: 'Name',
            icon: 'xiugai.png',
            operate: 'edit'
          },
          {
            id: 5,
            title: this.$t("account.listData.gender"),
            field: 'Sex',
            icon: 'xiugai.png',
            operate: 'edit'
          }
        ],
        [
          {
            id: 6,
            title: this.$t("account.listData.email"),
            field: 'Email',
            icon: 'xiugai.png',
            operate: 'edit'
          },
          {
            id: 7,
            title: this.$t("account.listData.loginPassword"),
            type: 'password',
            icon: 'xiugai.png',
            operate: 'edit'
          },
          {
            id: 3,
            title: this.$t("account.listData.taobaoAccount"),
            field: 'TaobaoCode',
            icon: 'fuzhi.png',
            operate: 'copy'
          },
          {
            id: 8,
            title: this.$t("account.listData.phoneNumber"),
            field: 'PhoneNumber',
            icon: 'xiugai.png',
            operate: 'edit'
          }
        ]
      ]
    }
  },
  filters: {

  },
  data() {
    return {
      form: {},
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      file: undefined,
      action: ``,
      avatarUrl: "",
      avatarVisible: false,
      options: [],
      loading: false,
      EditAccountVisible: false,
      PasswordVisible: false,
    }
  },
  methods: {
    editAvatar() {
      if (!this.file) return;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('FileName', `${this.userInfo.Id}.jpg`);
      formData.append('Path', 'ClientPortrait');
      this.loading = true
      uploadImage(formData).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          const data = {
            PicturePath: Data.ServerPath
          }
          updateAvatar(data).then(response => {
            this.loading = false
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.$message.success(this.$t('account.userChangeSuccess'))
              this.$store.dispatch('user/getInfo')
              this.avatarVisible = false
            } else {
              this.$message.warning(Msg)
            }
          }).catch(() => { this.loading = false })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    progressAvatar() {
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t('common.imageValidate'));
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.avatarUrl = e.target.result;
      };
      this.file = file
      return isLt2M;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (formName === 'ruleForm') {
            const data = {
              userName: this.form.Name,
              Sex: this.form.Sex,
              PhoneNumber: `${this.form.Tel} ${this.form.PhoneNumber}`,
              email: this.form.Email
            }
            UpdateUser(data).then(response => {
              this.loading = false;
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message({
                  message: this.$t('account.userChangeSuccess'),
                  type: "success"
                });
                this.$store.dispatch('user/getInfo')
                this.EditAccountVisible = false
              } else {
                this.$message.warning(Msg)
              }
            }).catch(() => { this.loading = false })
          } else {
            const data = {
              oldPassword: this.ruleForm.oldPassword,
              newPassword: this.ruleForm.newPassword,
              confirmPassword: this.ruleForm.confirmPassword,
            }
            UpdatePwd(data).then(response => {
              this.loading = false;
              if (response.Success) {
                this.ruleForm = {}
                this.$message({
                  message: this.$t('account.passwordChangeSuccess'),
                  type: "success"
                });
                setTimeout(() => {
                  this.$store.dispatch('user/logout')
                }, 3000);
              }
            }).catch(() => { this.loading = false })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    validatePhoneNum(rule, value, callback) {
      if (this.form.Tel === '+86') {
        if (value.length !== 11) {
          callback(new Error(this.$t('account.enterCorrectPhoneNumber') || 'error'));
        } else {
          callback();
        }
      } else if (this.form.Tel === '+852') {
        if (value.length !== 8) {
          callback(new Error(this.$t('account.enterCorrectPhoneNumber') || 'error'));
        } else {
          callback();
        }
      } else {
        callback(); // 其他情况通过验证
      }
    },
    getCountryCodes() {
      const data = {
        tenantId: 1
      }
      CountryInfo(data).then(response => {
        const { Data } = response
        Data.forEach(item => {
          this.options.push({
            label: item.Name,
            value: item.Code
          })
        })
      })
    },
    processAccount(row) {
      if (row.operate === 'copy') {
        let that = this;
        clipboardCopy(this.userInfo[row.field])
            .then(() => {
              that.$message({
                message: this.$t('account.copySuccess'),
                type: 'success'
              });
            })
            .catch(err => {
              console.error(this.$t('account.copyTextFailed'), err);
            });
      } else if (row.operate === 'avatar') {
        this.file = undefined
        this.avatarUrl = undefined
        this.avatarVisible = true
      } else {
        if (row.type === 'password') {
          this.PasswordVisible = true;
        } else {
          this.form = {
            Name: this.userInfo.Name,
            Sex: this.userInfo.Sex,
            Email: this.userInfo.Email,
            Tel: this.userInfo.PhoneNumber ? this.userInfo.PhoneNumber.split(" ")[0] : "",
            PhoneNumber: this.userInfo.PhoneNumber ? this.userInfo.PhoneNumber.split(" ")[1] : ""
          };
          this.EditAccountVisible = true;
        }
      }
    },
    LogoffAccount() {
      this.$confirm(this.$t('account.confirmDelete'), this.$t('account.prompt'), {
        confirmButtonText: this.$t('account.confirm'),
        cancelButtonText: this.$t('account.cancel'),
        type: 'warning'
      }).then(() => {
        this.$prompt(this.$t('account.enterPassword'), this.$t('account.deleteAccount'), {
          confirmButtonText: this.$t('account.confirm'),
          cancelButtonText: this.$t('account.cancel'),
          inputPattern: /\S/,
          inputType: "password",
          inputErrorMessage: this.$t('account.passwordNotEmpty')
        }).then(({ value }) => {
          LogoffUser({ Password: value }).then(response => {
            if (response.Success) {
              this.$message({
                type: 'success',
                message: this.$t('account.deleteRequestSuccess', { time: this.$options.filters.formatDate(response.Data) })
              });
              this.$store.dispatch('user/logout');
            }
          });
        }).catch(() => {});
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('account.cancelDelete')
        });
      });
    },
    parseSex(value) {
      if (value === 1) {
        return this.$t("account.filters.female");
      } else if (value === 2) {
        return this.$t("account.filters.male");
      } else if (value === 3) {
        return this.$t("account.filters.secret");
      } else {
        return this.$t("account.filters.unlimited");
      }
    }
  },
  created() {
    this.getCountryCodes()
  }
}

</script>

<style scoped lang="scss">
.icon-img {
  width: 1.5rem;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
}
.container {
  width: 100%;
  padding: 1.5rem 2.5rem;
  position: relative;
  box-sizing: border-box;
  table {
    position: relative;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    box-sizing: border-box;
    td, th {
      border: 0.1rem solid #E6E6E6;
    }
  }
  .safe-box {
    font-size: 1.6rem;
    flex-basis: 25%;
    border: 0.1rem solid #E6E6E6;
    height: 6rem;
    .safe-image {
      width: 2.5rem;
    }
  }
}
</style>
