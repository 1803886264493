<template>
  <el-form ref="ruleForm" :rules="rules" label-position="left" :model="form" label-width="90px">
      <address-select ref="addr" @refresh="init" @select-address="selectAddress" :options="AddrOptions"></address-select>
      <div class="head-title">
        <div class="line"></div>
        <div>{{ $t('mergerCard.delivery') }}</div>
      </div>
      <div class="u-between-flex">
        <el-form-item :label="$t('mergerCard.Timer')" prop="TimerId" style="flex-basis: 45%">
          <el-select size="small" style="width: 100%;" v-model="form.TimerId" :placeholder="$t('mergerCard.placeholderTimer')">
            <el-option
                v-for="item in TimerOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('mergerCard.Channel')" prop="ChannelId" style="flex-basis: 45%">
          <el-select size="small" style="width: 100%;" v-model="form.ChannelId" :placeholder="$t('mergerCard.placeholderChannel')">
            <el-option
                v-for="item in ChannelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
</template>

<script>
import AddressSelect from "@/components/AddressSelect.vue";
import {getTimer} from "@/api/system";

export default {
  name: "deliveryForm",
  components: { AddressSelect },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      AddrOptions: [],
      TimerOption: [],
      ChannelOptions: [],
    }
  },
  computed: {
    rules() {
      return {
        ChannelId: [{ required: true, message: this.$t('mergerCard.placeholderChannel'), trigger: 'blur' }],
        TimerId: [{ required: true, message: this.$t('mergerCard.placeholderTimer'), trigger: 'blur' }],
      }
    },
  },
  methods: {
    init() {
      return new Promise((rs) => {
        this.$store.dispatch('webSite/getAddress').then(data => {
          this.AddrOptions = data;
          this.AddrOptions.sort((a, b) => {
            if (a.IsDefaultAddress && !b.IsDefaultAddress) return -1;
            if (!a.IsDefaultAddress && b.IsDefaultAddress) return 1;
            return 0;
          });
          const defaults = this.AddrOptions.filter(item => { return item.IsDefaultAddress })
          if (defaults.length) {
            this.$refs.addr.onNav(defaults[0])
          } else if (this.AddrOptions.length) {
            this.$refs.addr.onNav(this.AddrOptions[0])
          } else {
            rs(false)
          }
          rs(true)
        })
      })
    },
    getTimer() {
      return new Promise((rs) => {
        const data = {
          PageIndex: 1,
          PageRows: 100
        }
        getTimer(data).then(response => {
          const { Data } = response
          this.TimerOption = Data.map(item => {
            return { label: item.Name, value: item.Id }
          })
          this.$set(this.form, 'TimerId', Data[0].Id)
          rs(true)
        })
      })

    },
    submitForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        });
      })
    },
    selectAddress(itemId) {
      this.$set(this.form, 'ClientAddressId', itemId)
      const CurrencyName = this.AddrOptions.filter(item => {
        return item.Id === itemId
      })[0].CurrencyName
      this.$set(this.form, 'CurrencyName', CurrencyName)
      if (this.form.TimerId) {
        this.$emit('reset')
      }
    }
  }
}
</script>



<style scoped lang="scss">
.head-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: fangsong;
  .line {
    width: 5px;
    height: 15px;
    border-radius: 2.5px;
    margin-right: 8px;
    background: #E4393C;
  }
}
::v-deep .el-input--suffix .el-input__inner {
  font-size: 1.5rem;
}
::v-deep .el-form-item__label {
  font-size: 1.5rem;
}
::v-deep .el-form-item {
  margin-bottom: 15px;
  margin-top: 7px;
}
</style>
