<template>
    <div class="card-container default-font">
      <div class="ellipsis mb8">{{ $t('order.mainbillcode') }}：{{ order.MainBillCode }}</div>
      <div class="ellipsis">{{ $t('order.goodsname') }}：{{ order.GoodsName }}</div>
    </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  }
}

</script>

<style scoped lang="scss">
.card-container {
  padding: 0.2rem;
  font-size: 1.4rem !important;
  .ellipsis {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
