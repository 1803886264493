<template>
  <div class="container">
    <div style="width: 100%" class="mb20">
      <h3 style="text-align: center">{{ name }}</h3>
      <div style="text-align: center; font-size: 1.8rem; color: #21C1B7">{{ $t('message.releaseTime') }}：{{ createTime | formatDate }}</div>
    </div>
    <div v-html="content"></div>
  </div>
</template>

<script>
import { getNoticeDetail } from "@/api/system";
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      newsId: undefined,
      content: "",
      name: "",
      createTime: ""
    }
  },
  computed: {
    ...mapGetters(['shopID'])
  },
  created() {
    this.newsId = this.$route.query.id;
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        Id: this.newsId
      }
      getNoticeDetail(params).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.content = Data.Content;
          this.name = Data.Name;
          this.createTime = Data.CreateTime;
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
.container {
  min-height: calc(100vh - (10rem + 44rem));
  width: 85%;
  margin: 4rem auto;
  padding: 1rem;
  box-shadow: 0.1rem 0.1rem 1rem #999;
  font-size: 3rem;
}
</style>
