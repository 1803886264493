<template>
  <div class="container">
    <div class="notice-title">{{ $t('consolidation.noticeTitle') }}</div>
    <div class="notice-item" v-html="content" v-loading="loading">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [],
      loading: false
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('webSite/getShopMatters', 9).then(data => {
      this.loading = false
      this.content = data[0].Content
    })
  }
}

</script>

<style scoped lang="scss">
.container {
  padding: 2rem 1.5rem;
  min-height: calc(100vh - (10rem + 44rem));
  .notice-item {
    padding: 1.5rem;
    background: #F7F8FA;
  }
  .notice-title {
    font-size: 1.6rem;
    color: #194D8F;
    padding: 1.5rem;
    border-bottom: 0.1rem solid rgba(230,230,230,1);
  }
}
</style>
