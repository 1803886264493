<template>
  <div class="agent-center">
    <div class="title">{{ $t('agent.title') }}</div>
    <div class="agent-box u-event-flex">
      <div class="share-box">
        <div class="qr-code">
          <canvas ref="qrcodeCanvas" :style="canvasStyle"></canvas>
          <div class="mine-code">{{ $t('agent.referralCode') }}：{{ userInfo.Id }}</div>
          <div class="center">
            <el-image :src="require('@/static/images/member/agent/agent.png')" class="center-img"></el-image>
          </div>
        </div>
        <div class="operate-btn">
          <button class="round-btn" @click="downloadQrCode">{{ $t('agent.download') }}</button>
          <button class="round-btn" @click="copyLink">{{ $t('agent.copyLink') }}</button>
        </div>
      </div>
      <div class="show-box">
        <div class="mr15">
          <div class="show-number">
<!--            <CountTo :startVal='0' :endVal='userInfo.WithdrawalMoney' :duration='3000' />-->
            {{ userInfo.WithdrawalMoney }}
          </div>
          <div class="show-title" style="color: #88EBCD">{{ $t('agent.WithdrawalMoney') }}</div>
        </div>
        <el-image :src="require('@/static/images/member/agent/money.png')" class="show-img"></el-image>
        <div class="line" style="background-color: #88EBCD"></div>
      </div>
      <div class="show-box">
        <div>
          <div class="show-number">
            <CountTo :startVal='0' :endVal='promotionNumber' :duration='3000' />
<!--            {{ promotionNumber }}-->
          </div>
          <div class="show-title" style="color:#FCC30E;">{{ $t('agent.promotionNumber') }}</div>
        </div>
        <el-image :src="require('@/static/images/member/agent/vip.png')" class="show-img"></el-image>
        <div class="line" style="background-color: #FCC30E"></div>
      </div>
      <div class="withdrawal-btn" @click="visible=true">{{ $t('agent.withdrawal') }}</div>
    </div>
    <div class="title">{{ $t('agent.member') }}</div>
    <div class="table-box">
      <el-table v-loading="loading" size="mini" :data="listData" :header-cell-style="headerStyle">
        <el-table-column :label="$t('agent.id')" prop="ClientId" align="center"></el-table-column>
        <el-table-column :label="$t('agent.name')" prop="ClientName" align="center"></el-table-column>
        <el-table-column :label="$t('agent.createTime')" prop="CreateTime" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.CreateTime | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('agent.lastLoginTime')" prop="LastLoginTime" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.LastLoginTime | formatDate }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt20" style="width: 100%; text-align: center">
        <el-pagination
            background
            :current-page.sync="pageIndex"
            layout="prev, pager, next"
            :page-size="pageSize"
            @current-change="handleChange"
            :total="total">
        </el-pagination>
      </div>

    </div>
    <el-dialog :title="$t('agent.withdrawal')" :visible.sync="visible" width="25%">
      <div class="balance">
        <el-image :src="require('@/static/images/member/recharge/money.png')" class="balance-icon"></el-image>
        <div>{{ $t('agent.withdrawalIncome') }}：{{ userInfo.WithdrawalMoney }}
          <span class="pointer" style="color: #2583E5" @click="updateWithdrawalNumber">{{ $t('agent.allWithdrawal') }}</span>
        </div>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <el-form-item :label="$t('agent.label')" prop="withdrawalNumber">
          <el-input v-model="ruleForm.withdrawalNumber" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center; width: 100%">
        <el-button style="width: 40%" v-loading="loadBtnLoading" type="primary" @click="withdraw('ruleForm')">{{ $t('agent.submit') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { mapGetters } from "vuex"
import {getAgentList} from "@/api/member";
import {withdrawal} from "@/api/user";
import clipboardCopy from "clipboard-copy";
import CountTo from 'vue-count-to'
export default {
  name: "index",
  data() {
    return {
      visible: false,
      ruleForm: {
        withdrawalNumber: undefined
      },
      loadBtnLoading: false,
      url: "",
      copyLinkUrl: "",
      total: 0,
      promotionNumber: 0,
      pageIndex: 1,
      loading: false,
      pageSize: 10,
      listData: [],
      canvasStyle: {},
      rules: {
        withdrawalNumber: [
          { required: true, message: this.$t('agent.placeholder'), trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  components: {
    CountTo
  },
  methods: {
    headerStyle() {
      return 'background-color: #DAE5F2'
    },
    downloadQrCode() {
      const canvas = document.createElement('canvas');
      canvas.getContext('2d');

      // 生成二维码到 Canvas
      QRCode.toCanvas(canvas, this.url, function (error) {
        if (error) console.error(error);
        else {
          // 创建下载链接
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png'); // 将 Canvas 转为 data URL
          link.download = 'qrcode.png'; // 设置下载文件名

          // 将下载链接添加到页面并模拟点击
          document.body.appendChild(link);
          link.click();

          // 清理下载链接
          document.body.removeChild(link);
        }
      });
    },
    handleChange(current) {
      this.pageIndex = current;
      this.getData()
    },
    copyLink() {
      let that = this;
      clipboardCopy(this.copyLinkUrl)
      .then(() => {
        that.$message({
          message: this.$t('account.copySuccess'),
          type: 'success'
        });
      })
      .catch(err => {
        console.error(this.$t('account.copyTextFailed'), err);
      });
    },
    withdraw(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadBtnLoading = true;
          const data = {
            ClientId: this.userInfo.Id,
            WithdrawLimit: this.ruleForm.withdrawalNumber,
            Type: 1
          }
          withdrawal(data).then(response => {
            this.loadBtnLoading = false;
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.$message.success(this.$t('agent.success'));
              setTimeout(() => {
                this.$store.dispatch('user/getInfo');
                this.visible = false
              }, 1000)
            } else {
              this.$message.warning(Msg)
            }
          }).catch(() => { this.loadBtnLoading = false; })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getData() {
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
        ClientId: this.userInfo.Id,
        Type: 0
      }
      this.loading = true
      getAgentList(data).then(response => {
        this.loading = false
        const { Code, Data, Msg, Total } = response
        if (Code === 200) {
          const { RecommenderClientCount, GetClientAgentInfo, ShopUrl } = Data
          this.url = `${window.location.protocol}//${ShopUrl}/#/pages/login/register?AgentId=${this.userInfo.Id}`
          this.copyLinkUrl = `${window.location.origin}/#/register?AgentId=${this.userInfo.Id}`
          this.generateQRCode(this.url)
          this.listData = GetClientAgentInfo
          this.promotionNumber = RecommenderClientCount
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    updateWithdrawalNumber() {
      this.ruleForm.withdrawalNumber = this.userInfo.WithdrawalMoney;
    },
    generateQRCode(url) {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(canvas, url, {
        errorCorrectionLevel: 'H', // 容错级别，可选值为 'L', 'M', 'Q', 'H'
        margin: 0, // 二维码与边缘的间距
      }, (error) => {
        if (error) {
          console.error(error)
        } else {
          this.canvasStyle = {
            width: '15rem',
            height: '15rem'
          }
          this.$forceUpdate()
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  font-size: 1.5rem;
  width: 15rem;
  border: none;
  color: #7A7A7A;
  border-bottom: 2px solid #7A7A7A;
  border-radius: 0;
  height: 3rem;
  line-height: 3rem;
}
::v-deep .el-form-item__label {
  font-size: 1.5rem;
  color: #A3A3A3;
}
.agent-center {
  background-color: #E6E6E6;
  padding: 1rem;
  .balance {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2rem;
    padding-left: 1rem;
    height: 4rem;
    width: 25rem;
    color: #A49898;
    font-size: 1.5rem;
    background-color: #F7F0F0;
    .balance-icon {
      width: 2.5rem;
      margin-right: 0.8rem;
    }
  }
  .title {
    color: #BABABA;
    padding-left: 1rem;
    border-left: 8px solid #2A82E4;
    font-size: 1.5rem;
  }
  .agent-box {
    background-color: #fff;
    border-radius: 1.5rem;
    padding: 1rem 2.5rem 3rem 2.5rem;
    margin: 1.5rem 0;
    position: relative;
    .withdrawal-btn {
      color: #D0CACA;
      border: 1px solid #D0CACA;
      padding: 1.5rem;
      border-radius: 1rem;
      font-size: 1.4rem;
      cursor: pointer;
    }
    .show-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-radius: 0.8rem;
      border: 2px solid #EEEAEA;
      position: relative;
      .line {
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: 1px;
        left: 0;
      }
      .show-number {
        font-size: 1.8rem;
        color: #808080;
      }
      .show-title {
        font-size: 1.5rem;
      }
      .show-img {
        width: 4.5rem;
      }
    }
    .share-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .qr-code {
        position: relative;
        width: 15rem;
        height: 15rem;
        .mine-code {
          position: absolute;
          bottom: -2.5rem;
          width: 100%;
          text-align: center;
          color: #B8B2B2;
          font-size: 1.5rem;
        }
        .center {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          .center-img {
            width: 4rem;
          }
        }
      }
      .operate-btn {
        height: 15rem;
        display: flex;
        margin-left: 1rem;
        justify-content: space-between;
        flex-direction: column;
        .round-btn {
          background-color: #2A82E4;
          color: #ffffff;
          font-size: 1.5rem;
          padding: 1rem 2.5rem;
          border-radius: 1rem;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
  .table-box {
    width: 100%;
    margin-top: 1.5rem;
    min-height: 20rem;
    background-color: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
}
</style>
