<template>
  <div class="container">
    <div class="tabs">
      <div class="tabs-header">
        <div class="u-start-flex" v-for="(tab, index) in computedTabs" :key="index">
          <div :class="['tab-header', { active: currentTab === index }]" @click="changeTab(index, tab)">
            <span class="default-font">{{ tab.title }}</span>
          </div>
          <div v-if="index+1 !== computedTabs.length" class="tab-header-line">|</div>
        </div>
      </div>
      <div class="tabs-content">
        <div v-for="(tab, index) in computedTabs" :key="index" v-show="currentTab === index">
          <div :class="[`u-start-flex`, `parent${index}`, 'parent']" @mousewheel="onWheel" :style="{width: computedWidth, 'overflow-x': 'auto'}">
            <coupon v-for="(coupon, i) in computedCoupons"
                    :style="{animationDelay: `${ i * 0.25 }s`, animationDuration: '0.25s'}"
                    class="animated fadeInRight"
                    :length="computedCoupons.length" :key="coupon.Id" :coupon="coupon"></coupon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coupon from "@/views/member/coupon/components/coupon.vue";
export default {
  data() {
    return {
      currentTab: 0,
      scrollX: 0,
      status: 2,
    }
  },
  computed: {
    computedWidth() {
      return '100%'
      // if (this.computedCoupons.length <= 3) {
      //   return `${ 25 * this.computedCoupons.length }%`
      // } else {
      //   return '100%'
      // }
    },
    computedTabs() {
      return [
        // { title: `全部（${this.coupons.length}张）`, status: 0 },
        { title: `可使用（${this.coupons.filter(item => { return item.Status === 2 }).length}张）`, status: 2 },
        { title: `已使用（${this.coupons.filter(item => { return item.Status === 4 }).length}张）`, status: 4 },
        { title: `待生效（${this.coupons.filter(item => { return item.Status === 1 && !item.IsUse }).length}张）`, status: 1 },
        { title: `已过期（${this.coupons.filter(item => { return item.Status === 3 }).length}张）`, status: 3 },
      ]
    },
    computedCoupons() {
      if (this.status) {
          return this.coupons.filter(item => { return item.Status === this.status })
      } else {
        return this.coupons
      }
    }
  },
  props: {
    coupons: {
      type: Array,
      default: () => []
    }
  },
  components: {
    coupon
  },
  methods: {
    changeTab(index, tab) {
      this.currentTab = index;
      this.status = tab.status;
      this.$forceUpdate()
    },
    onWheel(event) {
      const scrollLeft = document.querySelector(`.parent${this.currentTab}`).scrollLeft;
      const offsetWidth = document.querySelector(`.parent${this.currentTab}`).offsetWidth;
      const scrollWidth = document.querySelector(`.parent${this.currentTab}`).scrollWidth;
      if (event.wheelDelta < 0 && scrollLeft + offsetWidth < scrollWidth) {
        document.querySelector(`.parent${this.currentTab}`).scrollLeft =
            document.querySelector(`.parent${this.currentTab}`).scrollLeft + 100;
      } else if (event.wheelDelta > 0 && scrollLeft > 0) {
        document.querySelector(`.parent${this.currentTab}`).scrollLeft =
            document.querySelector(`.parent${this.currentTab}`).scrollLeft - 100;
      }
      event.preventDefault();
    }
  }
}
</script>

<style scoped lang="scss">
.parent::-webkit-scrollbar {
  height: 5px; /* 设置滚动条宽度为2px */
}

.parent::-webkit-scrollbar-thumb {
  background-color: lightblue; /* 设置滚动条颜色为天蓝色 */
  border-radius: 20px; /* 设置滚动条两边为20px的半圆 */
}
.tab-header-line {
  color: #979797;
  margin: 0 0.8rem;
}
.container {
  .tabs {
    .tabs-header {
      display: flex;
      align-items: center;
      justify-content: center;
      .tab-header {
        cursor: pointer;
        padding: 1rem 0;
        border-bottom: 0.2rem solid transparent;
      }
      .active {
        color: #194D8F;
        border-bottom: 0.2rem solid #194D8F;
      }
    }
    .tabs-content {
      margin-top: 3rem;
    }
  }
}
</style>
