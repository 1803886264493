<template>
  <div class="container">
    <div class="title">{{ $t('contraband.title') }}</div>
    <div class="contraband-box" v-html="content"></div>
    <div class="contraband-box" v-if="false">
      <div class="contraband-item u-start-flex" v-for="item in contraband" :key="item.id">
        <el-image fit="cover" :src="require(`@/static/images/Consolidation-Notice/contraband/${item.icon}`)" class="contraband-img"></el-image>
        <div>
          <div class="contraband-title">{{ item.title }}</div>
          <div class="contraband-introduction">{{ item.introduction }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      content: '',
      loading: false,
      contraband: [
        {
          id: 1,
          icon: 'weapon.png',
          title: '武器彈藥類',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 2,
          icon: 'tool.png',
          title: '管制刀具類',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 3,
          icon: 'combustible.png',
          title: '易燃, 易腐爛, 酒及酒精飲品',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 4,
          icon: 'narcotics.png',
          title: '毒品類或吸毒相關器具類',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 5,
          icon: 'chemistry.png',
          title: '危險化學化工品',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 6,
          icon: 'hydraulic.png',
          title: '液壓灌',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 7,
          icon: 'liquid.png',
          title: '易漏液體類',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 8,
          icon: 'weathering.png',
          title: '妨害風化類',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 9,
          icon: 'trademark.png',
          title: '侵犯商標類',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 10,
          icon: 'currency.png',
          title: '貨幣類',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        },
        {
          id: 11,
          icon: 'plant.png',
          title: '活動物, 植物或種子',
          introduction: '槍支、子彈、炮彈、地雷、炸彈、仿真槍、瞄準器、槍械配件類'
        }
      ]
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('webSite/getShopMatters', 10).then(data => {
      this.loading = false
      this.content = data[0].Content
    })
  }
}

</script>

<style scoped lang="scss">
.container {
  padding: 2rem 1rem;
  min-height: calc(100vh - (10rem + 44rem));
  .title {
    padding: 1.5rem 0;
    border-bottom: 0.1rem solid rgba(230,230,230,1);
    color: #194D8F;
    font-size: 1.4rem;
  }
  .contraband-box {
    padding: 2rem 1.5rem;
    .contraband-item {
      margin-bottom: 2rem;
      .contraband-img {
        width: 3.6rem;
        margin-right: 3rem;
      }
      .contraband-title {
        font-size: 1.4rem;
        color: #194D8F;
        margin-bottom: 1rem;
      }
      .contraband-introduction {
        color: #636363;
        font-size: 1.2rem;
      }
    }
  }
}
</style>
