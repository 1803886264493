<template>
  <div class="container">
    <div class="title">{{ title }}</div>
    <div class="item" v-html="content" v-loading="loading"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      content: "",
      title: "",
      loading: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      const Id = this.$route.query.id
      this.content = this.help.filter(item => {  return item.Id == Id })[0].Content
      this.title = this.help.filter(item => {  return item.Id == Id })[0].Title
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['help'])
  },
  watch: {
    '$route': {
      handler() {
        this.getData()
      },
      deep: true
    }
  }
}

</script>



<style scoped lang="scss">
.container {
  padding: 2rem 1.5rem;
  .item {
    padding: 1.5rem;
    background: #F7F8FA;
  }
  .title {
    font-size: 1.6rem;
    color: #194D8F;
    padding: 1.5rem;
    border-bottom: 0.1rem solid rgba(230,230,230,1);
  }
}
</style>
