<template>
  <div>
    <div class="exhibit-box">
      <recharge-inventory v-if="orderType==='recharge'"></recharge-inventory>
      <order-card v-else :order="order"></order-card>
    </div>
    <div class="payment-box">
      <div class="mr8">{{ $t('paymentCard.paymentMethod') }}：</div>
      <div class="method-box">
        <div v-for="payment in paymentList" :key="payment.Id" :class="{ 'btn-active': payment.Id == stateCurrent }" class="u-start-flex pointer pay-box" @click="onNav(payment)">
          <el-image class="method-img mr8" :src="payment.HostIcon"></el-image>
<!--          <span style="margin-right: 20px;">手续费：{{ `${paymentItem(paymentTxt).HandlingCharge}%` }}</span>-->
          <span style="flex-grow: 1">
            {{ `${payment.Name}(${payment.CurrencyName})` }}
            <span v-if="payment.paymentTxt === 'Balance'">({{ userInfo.Money }})</span>
          </span>
          <span class="rem">{{ payment.Rem }}</span>
          <div class="flag" v-if="stateCurrent===payment.Id"></div>
          <div class="barge" v-if="stateCurrent===payment.Id">
            <i class="el-icon-check"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="price-box">
      <div style="margin-bottom: 10px">{{ orderType === 'recharge' ? $t('paymentCard.rechargeAmount') : $t('paymentCard.paymentAmount') }}：<span style="color: #EA4A41">{{ order.Receivables || rechargeNumber }}</span>元
        <span v-if="stateCurrent">（<span style="color: #EA4A41">{{ currentMoney }}</span>{{ currencyIdentification }}）</span>
      </div>
    </div>
  </div>
</template>

<script>
import orderCard from "@/views/member/order/components/order-card.vue";
import RechargeInventory from "@/components/RechargeInventory.vue";
import {mapGetters} from "vuex";
import {getCurrentMoney} from "@/api/system";

export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    orderType: {
      type: String,
      default: "goods"
    },
    rechargeNumber: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      stateCurrent: "",
      paymentIMG: {
        Wechat: 'order_wx_pay.png',
        Alipay: 'order_ali_pay.png',
        Balance: 'order_wallet_pay.png',
        CashonDelivery: 'order1.png',
        PayAfterPacking: 'order2.png',
        BankCard: 'order3.png',
        Other: 'Offline.png',
        Offline: 'taoBao.png',
        CollectionOfDeductions: 'taoBao.png'
      },
      currentMoney: "",
      currencyIdentification: ''
    }
  },
  methods: {
    onNav(item) {
      this.stateCurrent = item.Id
      this.currencyIdentification = item.CurrencyCode
      this.$emit('radio-change', {
        payType: this.payType,
        payTypeId: this.stateCurrent,
        url: item.Link,
        OpeningName: item.OpeningName,
        Account: item.Account,
        BankDeposit: item.BankDeposit,
        ImageHostURL: item.ImageHostURL,
      })
      this.getCurrentMoney()
    },
    getCurrentMoney() {
      const data = {
        PayTypeId: this.stateCurrent,
        Money: this.order.Receivables || this.rechargeNumber,
        ShopId: this.shopID
      }
      getCurrentMoney(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.currentMoney = Data.PayMoney
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  },
  computed: {
    ...mapGetters(['payTypes', 'shopID', 'userInfo']),
    IsOpenPayAfterPacking() {
      return this.order.IsOpenPayAfterPacking || false
    },
    paymentList() {
      if (this.orderType === 'recharge') {
        return this.payTypes.filter(item => {
          return this.IsOpenPayAfterPacking ? item.OpenRecharge : item.OpenRecharge && item.PayType !== 6
        })
      } else if (this.orderType === 'goods') {
        return this.payTypes.filter(item => {
          return this.IsOpenPayAfterPacking ? item.OpenPlaceOrder : item.OpenPlaceOrder && item.PayType !== 6
        })
      } else {
        return this.payTypes.filter(item => {
          return this.IsOpenPayAfterPacking ? item.OpenPayment : item.OpenPayment && item.PayType !== 6
        })
      }
    },
    payType() {
      if (this.stateCurrent) {
        return this.payTypes.filter(item => { return item.Id === this.stateCurrent })[0].PayTypeTxt
      } else {
        return ""
      }
    }
  },
  components: {
    orderCard,
    RechargeInventory
  }
}
</script>

<style scoped lang="scss">
.exhibit-box {
  width: 75%;
  margin: 0 auto;
  border: 1px solid #D8D9DB;
  border-radius: 1.5rem;
  padding: 2rem;
  .card-container {
    padding: 0 !important;
  }
}
.price-box {
  width: 75%;
  margin: 0 auto;
  margin-top: 2rem;
  color: #444B5A;
  font-size: 1.6rem;
}
.payment-box {
  width: 75%;
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #444B5A;
  font-size: 1.6rem;
  .method-box {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    .btn-active {
      border: 1px solid #1182FB !important;
    }
    .pay-box {
      padding: 1rem 2rem;
      position: relative;
      overflow: hidden;
      border: 1px solid #D8D9DB;
      border-radius: 0.5rem;
      .flag {
        background-color: #1182FB;
        position: absolute;
        width: 5rem;
        height: 2rem;
        bottom: 0;
        right: -2.5rem;
        transform: rotateZ(-45deg);
      }
      .rem {
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 限制在2行 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; /* 这对于多行文本其实不直接生效，但保持样式完整性 */
        white-space: normal;
        font-size: 12px;
        color: #898989;
        width: 10rem;
      }
      .barge {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #ffffff;
      }
      .method-img {
        width: 3rem;
        min-width: 3rem;
      }
    }
  }
}
</style>
