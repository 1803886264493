<template>
  <div class="container">
    <user-info></user-info>
    <div class="grid-line"></div>
    <domestic :count-data="countData"></domestic>
    <Address></Address>
    <el-dialog
        top="10vh"
        :title="$t('personal.notice')"
        :visible.sync="noticeVisible"
        width="50%"
        center>
      <div class="body" style="height: calc(75vh - 130px); overflow-y: scroll">
        <div v-html="notices.length && notices[0].Content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="noticeVisible = false">{{ $t('personal.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import userInfo from "./components/user-info.vue"
import domestic from "./components/domestic.vue"
import Address from "@/views/member/personal/components/address.vue";
import { mapGetters } from "vuex"
import { getPriceInfo } from "@/api/user"
import {getNotices} from "@/api/system";
export default {
  data() {
    return {
      countData: {},
      noticeVisible: false,
      notices: []
    }
  },
  computed: {
    ...mapGetters(['shopID', 'showDialog'])
  },
  components: {
    userInfo,
    domestic,
    Address
  },
  methods: {
    getNotices() {
      const data = {
        PageIndex: 1,
        PageRows: 1,
        ShopId: this.shopID,
      }
      getNotices(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.notices = Data;
          if(Data.length) this.noticeVisible = true;
          this.$store.commit('user/SET_SHOW_DIALOG', false)
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    getData() {
      getPriceInfo().then(response => {
        const { Data } = response
        this.countData = Data;
        this.$forceUpdate()
      })
    }
  },
  created() {
    this.getData()
    this.$store.dispatch('user/getInfo')
    if (this.showDialog) this.getNotices()
  }
}

</script>

<style scoped lang="scss">
.grid-line {
  width: 100%;
  height: 1rem;
  background-color: #F4F4F4;
}

.container {
  width: 100%;
  //.body {
  //  max-height: 40rem;
  //  overflow: auto;
  //}
}
</style>
