<template>
  <el-dialog  :before-close="close" :title="$t('personal.signCalendar')" width="420px" :visible.sync="show">
    <div class="body">
      <el-calendar>
        <template slot="dateCell" slot-scope="{date}">
          <div class="calendar-box">
            <el-image v-if="isToday(date)" class="calendar-png" :src="require('@/static/images/member/personal/confrim_current.png')"></el-image>
            <el-image v-else-if="containsDateOnly(listData, date)" class="calendar-png" :src="require('@/static/images/member/personal/confrim.png')"></el-image>
            <span v-else>{{ date.getDate() }}</span>
          </div>
        </template>
      </el-calendar>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import {sign} from "@/api/user";
export default {
  name: "SignBox",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      listData: []
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        sign().then(response => {
          this.listData = response.Data.SignInDates
          const GiftIntegral = response.Data.GiftIntegral
          if (GiftIntegral) {
            this.$message.success(this.$t('personal.signSuccess', [GiftIntegral]))
            this.$store.dispatch('user/getInfo')
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    isToday(date) {
      const inputDate = new Date(date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      inputDate.setHours(0, 0, 0, 0);
      return inputDate.getTime() === today.getTime();
    },
    containsDateOnly(timeArray, targetDateStr) {
      const targetDate = new Date(targetDateStr);
      targetDate.setHours(0, 0, 0, 0);
      for (let i = 0; i < timeArray.length; i++) {
        const arrayDate = new Date(timeArray[i]);
        arrayDate.setHours(0, 0, 0, 0);
        if (arrayDate.toISOString().split('T')[0] === targetDate.toISOString().split('T')[0]) {
          return true;
        }
      }
      return false;
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 1.7rem;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-calendar-day {
  height: 48px;
}
.body {
  padding: 1rem;
  .calendar-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
