<template>
  <div class="order-container">
    <div class="queryItems mb10">
      <el-input v-model="queryCode" size="mini" class="mr15" clearable style="width: 300px" :placeholder="$t('order.placeholder')"></el-input>
      <el-button type="primary" class="mr15" size="mini" icon="el-icon-search" @click="getData">{{ $t('domestic.search') }}</el-button>
    </div>
    <div class="queryItems">
    </div>
    <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
    >
      <el-table-column label="订单号" prop="MainBillCode" show-overflow-tooltip align="center" width="150"></el-table-column>
      <el-table-column :label="$t('order.goodsname')" show-overflow-tooltip prop="GoodsName" align="center" width="150"></el-table-column>
      <el-table-column :label="$t('order.warehouse')" prop="WareHouseName" align="center"></el-table-column>
      <el-table-column :label="$t('order.carrier')" width="135" show-overflow-tooltip prop="CarrierName" align="center"></el-table-column>
      <el-table-column show-overflow-tooltip prop="CarrierBillCode" :label="$t('order.carrierbillcode')" align="center" width="130px">
      </el-table-column>
      <el-table-column :label="$t('order.timer')" show-overflow-tooltip prop="TimerName" align="center"></el-table-column>
      <el-table-column :label="$t('order.goodstype')" prop="GoodsTypeName" align="center"></el-table-column>
      <el-table-column :label="$t('order.Weight')" prop="Weight" align="center"></el-table-column>
      <el-table-column label="照片" align="center" width="150px">
        <template slot-scope="scope">
          <el-image class="shopee-img" :preview-src-list="scope.row.OrderItemDetails.map(item => { return item.Img })" :src="scope.row.OrderItemDetails[0].Img" v-if="scope.row.OrderItemDetails && scope.row.OrderItemDetails.length"></el-image>
        </template>
      </el-table-column>
      <el-table-column :label="$t('order.CreateTime')" width="160" prop="CreateTime" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getOrderList } from "@/api/member";

export default {
  data() {
    return {
      queryCode: "",
      PageIndex: 1,
      PageRows: 10,
      total: 0,
      loading: false,
      tableData: [],
      querys: {}
    }
  },
  created() {
    this.getData()
  },
  computed: {
    fields() {
      const tableFields = {}
      tableFields[this.$t('order.id')] = 'Id'
      tableFields[this.$t('order.mainbillcode')] = 'MainBillCode'
      tableFields[this.$t('order.warehouse')] = 'WareHouseName'
      tableFields[this.$t('order.goodsname')] = 'GoodsName'
      tableFields[this.$t('order.paystate')] = 'PayState'
      tableFields[this.$t('order.carrierbillcode')] = 'CarrierBillCode'
      tableFields[this.$t('order.carrier')] = 'CarrierName'
      tableFields[this.$t('order.timer')] = 'TimerName'
      tableFields[this.$t('order.goodstype')] = 'GoodsTypeName'
      tableFields[this.$t('order.Weight')] = 'Weight'
      tableFields[this.$t('order.CreateTime')] = 'CreateTime'
      return tableFields
    },
    timestampName() {
      const timestamp = Date.now()
      return `${this.$t('order.exportOrder')}${timestamp}.xls`
    },
  },
  methods: {
    handleChange(val) {
      this.PageIndex = val
      this.getData()
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    getData() {
      let QueryCodes = this.queryCode ? [this.queryCode] : undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        Codes: [],
        Type: this.orderType,
        QueryCodeType: 3,
        QueryCodes: QueryCodes,
        CarrierId: this.querys.carrier || undefined,
        TimerId: this.querys.timer || undefined,
        GoodsTypeId: this.querys.goodsType || undefined,
        WareHouseIds: this.querys.warehouse ? [this.querys.warehouse] : undefined,
        DestinationId: this.querys.destination || undefined,
        QueryType: 1,
        OrderSource: 4
      }
      this.loading = true
      getOrderList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          this.tableData = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.shopee-img {
  width: 120px;
  height: 60px;
  border-radius: 10px;
}
.order-container {
  .queryItems {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
  }
  padding: 2rem 2.5rem;
  .merger-btn {
    background-color: #194D8F;
    width: 16rem;
  }
  .arrived-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #19B14C;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
  .unarrive-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #ED4014;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
}
</style>
