<template>
  <div class="container">
    <div class="declare-title">{{$t('declare.title')}}</div>
    <div class="declare-form">
      <el-form ref="ruleForm" :rules="rules" size="mini" label-position="top" :model="form" label-width="auto">
        <el-form-item :label="$t('declare.code')" prop="courier">
          <el-input v-model="form.courier" :placeholder="$t('declare.codeplaceholder')"></el-input>
        </el-form-item>
        <div class="u-start-flex mb10" style="border-bottom: 0.1rem dashed #D5D5D5; padding-bottom: 1.5rem">
          <i class="el-icon-question mr8" style="color: #1890FF"></i>
          <span style="color: #1890FF; font-size: 1.2rem">{{$t('declare.findcode')}}</span>
        </div>
        <div class="u-between-flex">
          <el-form-item :label="$t('declare.storehouse')" prop="storehouse" style="flex-basis: 48%">
            <el-input v-model="form.storehouse"></el-input>
          </el-form-item>
          <el-form-item :label="$t('declare.company')" prop="company" style="flex-basis: 48%">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
        </div>
        <div class="u-between-flex">
          <el-form-item :label="$t('declare.type')" prop="type" style="flex-basis: 48%">
            <el-input v-model="form.type"></el-input>
          </el-form-item>
          <el-form-item :label="$t('declare.classification')" prop="classification" style="flex-basis: 48%">
            <el-input v-model="form.classification"></el-input>
          </el-form-item>
        </div>
        <div class="u-between-flex">
          <el-form-item :label="$t('declare.goodsname')" prop="goodsName" style="flex-basis: 48%">
            <el-input v-model="form.goodsName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('declare.goodsNum')" prop="goodsNum" style="flex-basis: 48%">
            <el-input v-model="form.goodsNum"></el-input>
          </el-form-item>
        </div>
        <div class="u-between-flex">
          <el-form-item :label="$t('declare.currency')" prop="currency" style="flex-basis: 48%">
            <el-input v-model="form.currency"></el-input>
          </el-form-item>
          <el-form-item :label="$t('declare.value')" prop="value" style="flex-basis: 48%">
            <el-input v-model="form.value"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="">
          <el-input
              type="textarea"
              :rows="3"
              :placeholder="$t('declare.placeholderMark')"
              v-model="form.mark">
          </el-input>
        </el-form-item>
        <el-form-item class="u-center-flex">
          <el-button plain @click="resetForm('ruleForm')" class="reset-btn">{{ $t('declare.reset') }}</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')" class="submit-btn">{{ $t('declare.submit') }}</el-button>
        </el-form-item>
      </el-form>
      <div class="hint-box mt20 u-start-flex">
        <el-image :src="require('@/static/images/member/declare/hint.png')" fit="cover" class="hint-img"></el-image>
        <div>
          <div class="mb10">{{ $t('declare.notice1') }}</div>
          <div>{{ $t('declare.notice2') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        courier: undefined,
        storehouse: undefined,
        company: undefined,
        type: undefined,
        classification: undefined,
        goodsName: undefined,
        goodsNum: undefined,
        currency: undefined,
        value: undefined,
        mark: ""
      },

    }
  },
  computed: {
    rules() {
      return {
        courier: [{ required: true, message: this.$t('declare.rules.courier.required'), trigger: 'blur' }],
        storehouse: [{ required: true, message: this.$t('declare.rules.storehouse.required'), trigger: 'blur' }],
        company: [{ required: true, message: this.$t('declare.rules.company.required'), trigger: 'blur' }],
        type: [{ required: true, message: this.$t('declare.rules.type.required'), trigger: 'blur' }],
        classification: [{ required: true, message: this.$t('declare.rules.classification.required'), trigger: 'blur' }],
        goodsName: [{ required: true, message: this.$t('declare.rules.goodsName.required'), trigger: 'blur' }],
        goodsNum: [{ required: true, message: this.$t('declare.rules.goodsNum.required'), trigger: 'blur' }],
        currency: [{ required: true, message: this.$t('declare.rules.currency.required'), trigger: 'blur' }],
        value: [{ required: true, message: this.$t('declare.rules.value.required'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}

</script>

<style scoped lang="scss">
.container {
  padding: 2rem 2.5rem;
  .declare-title {
    color: #194D8F;
    font-size: 1.6rem;
    padding-bottom: 2.5rem;
    border-bottom: 0.1rem solid rgba(230,230,230,1);
  }
  .declare-form {
    padding: 1rem 2rem;
    .reset-btn {
      width: 14rem;
      height: 3.6rem;
      vertical-align: middle;
      font-size: 1.4rem;
    }
    .submit-btn {
      background-color: #194D8F;
      width: 14rem;
      height: 3.6rem;
      vertical-align: middle;
      font-size: 1.4rem;
    }
    .hint-box {
      background: #FFFAF7;
      border: 0.1rem solid rgba(255,137,72,1);
      border-radius: 0.4rem;
      padding: 2rem 2.5rem;
      font-size: 1.4rem;
      .hint-img {
        margin-right: 2rem;
        width: 3.6rem;
      }
    }
  }
}
</style>
