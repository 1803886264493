<template>
  <div class="container">
    <div class="title">{{ $t('shippingcalculation.title') }}</div>
    <div class="form-box mt20">
      <el-form v-loading="loading" ref="ruleForm" :rules="rules" label-position="top" :model="form" label-width="auto">
        <div class="u-between-flex">
          <el-form-item :label="$t('shippingcalculation.warehouseName')" prop="warehouse" style="flex-basis: 48%">
            <el-select style="width: 90%;" v-model="form.warehouse">
              <el-option
                  v-for="item in warehouseOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('shippingcalculation.destination')" prop="destination" style="flex-basis: 48%">
            <el-select style="width: 90%" v-model="form.destination">
              <el-option
                  v-for="item in destinationOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="u-between-flex" style="border-bottom: 0.1rem dashed #EFEFEF;">
          <el-form-item :label="$t('shippingcalculation.goodtype')" prop="good_type" style="flex-basis: 48%">
            <el-select style="width: 90%" v-model="form.good_type">
              <el-option
                  v-for="item in goodTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <div class="form-title mb10 mt20"><span style="color: #F56C6C">* </span>{{ $t('shippingcalculation.volume') }}</div>
          <div class="u-between-flex volume">
            <el-form-item label="" prop="length" style="flex-basis: 23%">
              <el-input v-model="form.length" type="number" :placeholder="$t('shippingcalculation.length')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="width" style="flex-basis: 23%">
              <el-input v-model="form.width" type="number" :placeholder="$t('shippingcalculation.width')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="height" style="flex-basis: 23%">
              <el-input v-model="form.height" type="number" :placeholder="$t('shippingcalculation.height')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="weight" style="flex-basis: 23%">
              <el-input v-model="form.weight" type="number" :placeholder="$t('shippingcalculation.weight')"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="submit-box mt20">
          <el-button @click="submit('ruleForm')" size="medium" class="submit-btn" type="primary">{{ $t('shippingcalculation.submit') }}</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog :title="$t('shippingcalculation.result')" :visible.sync="dialogVisible">
      <div class="dialog-container">
        <div class="head">
          <div style="width: 25%; text-align: center;">
            <div style="font-size: 1.6rem" class="mb10">仓库</div>
            <div style="font-size: 2rem; color: #01A7FF">{{ reverseAddress.warehouseName }}</div>
          </div>
          <div><el-image fit="cover" class="right-img" :src="require('@/static/images/Consolidation-Notice/shipping/right.png')"></el-image></div>
          <div style="width: 25%; text-align: center">
            <div style="font-size: 1.6rem" class="mb10">{{ $t('shippingcalculation.destination') }}</div>
            <div style="font-size: 2rem; color: #01A7FF">{{ reverseAddress.destinationName }}</div>
          </div>
        </div>
        <div class="body">
          <div class="box" v-for="calculation in calculationInfos" :key="calculation.Id">
            <div class="notice">
              <div class="item" v-for="(item, index) in items" :key="index">
                <p>{{ dialogItems[item] }}</p>
                <p>{{ calculation[item] }}</p>
              </div>
            </div>
            <div class="mark" style="padding-left: 2rem">
              注：{{ calculation['Rem'] }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {computedShipping} from "@/api/member";
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(['shopID', 'TenantId']),
    dialogItems() {
      return {
        GoodsTypeNames: this.$t('shippingcalculation.dialogItems.goodsType'),
        TimerNames: this.$t('shippingcalculation.dialogItems.timer'),
        CarrierNames: this.$t('shippingcalculation.dialogItems.carrier'),
        Fee: this.$t('shippingcalculation.dialogItems.fee'),
        LengthExceed: this.$t('shippingcalculation.dialogItems.lengthExceed'),
        Carryweight: this.$t('shippingcalculation.dialogItems.carryWeight')
      }
    },
    rules() {
      return {
        warehouse: [{ required: true, message: this.$t('shippingcalculation.rules.selectWarehouse'), trigger: 'blur' }],
        destination: [{ required: true, message: this.$t('shippingcalculation.rules.selectDestination'), trigger: 'blur' }],
        good_type: [{ required: true, message: this.$t('shippingcalculation.rules.selectGoodType'), trigger: 'blur' }],
        length: [{ required: true, message: this.$t('shippingcalculation.rules.enterLength'), trigger: 'blur' }],
        width: [{ required: true, message: this.$t('shippingcalculation.rules.enterWidth'), trigger: 'blur' }],
        height: [{ required: true, message: this.$t('shippingcalculation.rules.enterHeight'), trigger: 'blur' }],
        weight: [{ required: true, message: this.$t('shippingcalculation.rules.enterWeight'), trigger: 'blur' }],
      }
    },
    items() {
      return Object.keys(this.dialogItems)
    },
    reverseAddress() {
      let warehouseName = ""
      let destinationName = ""
      if (!this.form.warehouse || !this.form.destination) {
        return { warehouseName, destinationName }
      } else {
        warehouseName = this.warehouseOption.filter(item => { return item.value === this.form.warehouse })[0].label
        destinationName = this.destinationOption.filter(item => { return item.value === this.form.warehouse })[0].label
        return { warehouseName, destinationName }
      }
    }
  },
  data() {
    return {
      calculationInfos: [],
      dialogVisible: false,
      form: {
        warehouse: undefined,
        destination: undefined,
        good_type: undefined,
        length: undefined,
        width: undefined,
        height: undefined,
        weight: undefined
      },
      loading: false,
      goodTypeOptions: [],
      destinationOption: [],
      warehouseOption: []
    }
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            VirtualWareHouseId: this.form.warehouse,
            GoodsTypeId: this.form.good_type,
            DestinationId: this.form.destination,
            Length: parseInt(this.form.length),
            Width: parseInt(this.form.width),
            Height: parseInt(this.form.height),
            Weight: parseInt(this.form.weight),
            ShopId: this.shopID,
            TenantId: this.TenantId
          }
          this.loading = true
          computedShipping(data).then(response => {
            this.loading = false
            this.calculationInfos = response.Data.calculationInfos
            this.dialogVisible = true
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    async pageInit() {
      this.loading = true
      await this.$store.dispatch('webSite/getGoodsType').then(data => {
        this.goodTypeOptions = data.map(item => { return { label: item.Name, value: item.Id } })
      })
      await this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.warehouseOption = data.map(item => { return { label: item.Name, value: item.Id } })
      })
      await this.$store.dispatch('webSite/getCountry').then(data => {
        this.destinationOption = data.map(item => { return { label: item.Name, value: item.Id } })
      })
      this.loading = false
    }
  },
  created() {
    this.pageInit()
  }
}
</script>

<style scoped lang="scss">
::v-deep .volume .el-input__inner {
  height: 5rem;
  background-color: #FBFBFB;
}
.dialog-container {
  padding: 2rem;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-img {
      height: 4rem;
    }
  }
  .body {
    padding: 1rem;
    .box {
      background-color: #F7F9FC;
      border: 1px solid #E7EAEF;
      padding: 1rem 0;
      .notice {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem; /* 可选，设置列之间的间距 */
        .item {
          text-align: center;
        }
      }
    }
  }
}
.container {
  padding: 3rem 2rem;
  .title {
    color: #194D8F;
    font-size: 1.6rem;
    padding: 1.5rem 0;
    border-bottom: 0.1rem solid rgba(230,230,230,1);
  }
  .form-box {
    .form-title {
      font-size: 1.6rem;
      color: #606266;
    }
    .submit-box {
      width: 100%;
      text-align: center;
      .submit-btn {
        background-color: #194D8F;
        width: 10rem;
      }
    }
  }
}
</style>
