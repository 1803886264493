<template>
  <div class="container">
    <div class="title">{{ $t('comment.mainTitle') }}</div>
    <div class="sub-title">{{ $t('comment.subTitle') }}</div>
    <vue-seamless-scroll class="scroll_box" :data="listData" :class-option="defineScroll">
      <div class="grid-box" v-for="(item, i) in listData" :key="i">
        <div class="item" v-for="comment in item" :key="comment.Id">
          <div class="head u-between-flex">
            <div class="u-start-flex">
              <el-image :src="comment.Clientprofile" class="head-img mr8"></el-image>
              <div>{{ comment.ClientName }}</div>
            </div>
            <el-rate disabled v-model="comment.StarLevel"></el-rate>
          </div>
          <div class="body">
            <div class="mb20">{{ comment.ReviewContent }}</div>
            <div v-if="comment.ReplyContent" class="replay">{{ $t('comment.reply') }}：{{ comment.ReplyContent }}</div>
          </div>
          <div class="date u-between-flex">
            <div>{{ comment.CreateTime | formatDate }}</div>
            <el-button plain icon="el-icon-thumb" round size="mini">999+</el-button>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import {getComment} from "@/api/system";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      comments: []
    }
  },
  methods: {
    getData() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
        ShopId: this.shopID,
        TenantId: this.TenantId
      }
      getComment(data).then(response => {
        const { Code, Data } = response
        if (Code === 200) this.comments = Data
      })
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapGetters(['shopID', 'TenantId']),
    listData() {
      let result = []
      for (let i = 0; i < this.comments.length; i+=6) {
        result.push(this.comments.slice(i, i + 6))
      }
      return result
    },
    defineScroll() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
}

</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background-color: #fff;
  padding: 5rem 0;

  .title {
    width: 100%;
    text-align: center;
    font-size: 2.4rem;
    font-family: "monospace";
    margin-bottom: 1rem;
  }

  .sub-title {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-family: "monospace";
    margin-bottom: 1.5rem;
  }
  .scroll_box {
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
    height: 42rem;
  }
  .grid-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* 创建两列，每列宽度相等 */
    grid-gap: 3rem; /* 设置网格项之间的间距 */
    .item {
      border-bottom: 1px solid #F4F4F4;
      padding: 1rem;
      font-size: 1.6rem;
      .head {
        margin-bottom: 1.5rem;
        .head-img {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
        }
      }
      .replay {
        color: red;
      }
      .date {
        color: #C8C8C8;
        font-size: 1.4rem;
      }
    }
  }
}
</style>