<template>
  <el-dialog :close-on-click-modal="false"  :before-close="close" :title="title" width="360px" :visible.sync="show">
    <div class="body">
      <el-form :rules="rules" size="small" ref="ruleForm" :model="form" label-width="80px">
        <el-form-item :label="$t('personal.withdrawalMoney.Amount')" prop="Amount">
          <el-input type="number" @input="checkWithdrawAmount" v-model="form.Amount"></el-input>
        </el-form-item>
        <el-form-item :label="$t('personal.withdrawalMoney.OpenName')" prop="OpenName">
          <el-input v-model="form.OpenName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('personal.withdrawalMoney.BankName')" prop="BankName">
          <el-input v-model="form.BankName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('personal.withdrawalMoney.BankCard')" prop="BankCard">
          <el-input v-model="form.BankCard"></el-input>
        </el-form-item>
        <el-form-item class="mt40">
          <el-button :disabled="userInfo.Money <= 0" size="medium" type="primary" @click="onSubmit">{{ $t('personal.withdrawalMoney.submit') }}</el-button>
          <el-button @click="close" size="medium">{{ $t('personal.withdrawalMoney.cancel') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import {withdrawalMoney} from "@/api/user";
export default {
  name: "WithdrawalBox",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      form: {
        OpenName: "",
        BankCard: "",
        Amount: 0,
        BankName: ""
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.resetForm()
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    title() {
      return this.$t('personal.withdrawalMoney.title', [this.userInfo.Money])
    },
    rules() {
      return {
        Amount: [
          { required: true, message: this.$t('personal.withdrawalMoney.placeholderAmount'), trigger: 'blur' },
        ],
        OpenName: [
          { required: true, message: this.$t('personal.withdrawalMoney.placeholderOpenName'), trigger: 'blur' }
        ],
        BankName: [
          { required: true, message: this.$t('personal.withdrawalMoney.placeholderBankName'), trigger: 'blur' }
        ],
        BankCard: [
          { required: true, message: this.$t('personal.withdrawalMoney.placeholderBankCard'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    checkWithdrawAmount() {
      if (this.form.Amount > this.userInfo.Money) {
        this.form.Amount = this.userInfo.Money;
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {
            ClientId: this.userInfo.Id,
            Account: this.form.BankCard,
            OpeningName: this.form.OpenName,
            BankDeposit: this.form.BankName,
            Money: this.form.Amount
          }
          withdrawalMoney(data).then(response => {
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.$message.success(this.$t('personal.withdrawalMoney.withdrawalSuccess'))
              this.close()
            } else {
              this.$message.warning(Msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.form = {
        OpenName: "",
        BankCard: "",
        Amount: 0,
        BankName: ""
      }
      this.$refs.ruleForm.resetFields();
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 1.7rem;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.body {
  padding: 1rem;
  .calendar-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
