<template>
  <div class="apply-container">
    <div class="apply-title">{{ $t('apply.title') }}</div>
    <div class="apply-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('apply.individualforecast')" name="single">
          <div class="claim-box">
            <div class="claim-title">
              <div style="width: 20%">{{ $t('apply.code') }}</div>
              <div style="width: 20%">{{ $t('apply.goodsname') }}</div>
              <div style="width: 10%">{{ $t('apply.goodstype') }}</div>
              <div style="width: 10%">{{ $t('apply.account') }}</div>
              <div style="width: 10%">{{ $t('apply.price') }}</div>
              <div style="width: 10%">{{ $t('apply.totalprice') }}</div>
              <div style="width: 20%">{{ $t('apply.clientrem') }}</div>
            </div>
            <div class="claim-body">
              <single-card :index="index" v-for="(item, index) in singleReports" :key="index"></single-card>
              <div class="operate mt20">
                <el-button size="mini" @click="addSingle" class="operate-btn mr8" type="primary" icon="el-icon-circle-plus-outline">{{ $t('apply.add') }}</el-button>
                <el-button size="mini" class="operate-btn" type="primary" @click="removeSingle">{{ $t('apply.remove') }}</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('apply.batchforecast')" name="batch">
          <div class="form-box">
            <el-form ref="ruleForm" :rules="rules" label-position="top" :model="form" label-width="auto">
              <div class="u-between-flex">
                <el-form-item :label="$t('apply.code')" prop="Codes" style="flex-basis: 48%">
                  <el-input type="textarea" v-model="form.Codes"></el-input>
                </el-form-item>
                <el-form-item :label="$t('apply.goodsname')" prop="GoodsName" style="flex-basis: 48%">
                  <el-input v-model="form.GoodsName"></el-input>
                </el-form-item>
              </div>
              <div class="u-between-flex">
                <el-form-item :label="$t('apply.goodstype')" prop="GoodsTypeId" style="flex-basis: 48%">
                  <el-select v-model="form.GoodsTypeId" style="width: 100%" :placeholder="$t('apply.goodstype')">
                    <el-option
                        v-for="item in goodTypes"
                        :key="item.Id"
                        :label="item.Name"
                        :value="item.Id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('apply.account')" prop="Account" style="flex-basis: 48%">
                  <el-input @change="handleChange" type="number" v-model="form.Account"></el-input>
                </el-form-item>
              </div>
              <div class="u-between-flex">
                <el-form-item :label="$t('apply.price')" prop="Price" style="flex-basis: 48%">
                  <el-input @change="handleChange" type="number" v-model="form.Price"></el-input>
                </el-form-item>
                <el-form-item :label="$t('apply.totalprice')" prop="total_price" style="flex-basis: 48%">
                  <el-input disabled v-model="form.total_price"></el-input>
                </el-form-item>
              </div>
              <el-form-item :label="$t('apply.clientrem')" prop="ClientRem" style="flex-basis: 48%">
                <el-input type="textarea" v-model="form.ClientRem"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="common-box">
        <div class="mt20 mb20"><el-button v-loading="loading" size="medium" class="submit-btn" type="primary" @click="submit">{{ $t('apply.submit') }}</el-button></div>
        <div class="prompt">
          <p style="color: #414141; font-size: 1.4rem">{{ $t('apply.noticeTitle') }}</p>
          <p v-html="content"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleCard from "@/views/member/apply/components/SingleCard.vue";
import { mapGetters } from "vuex"
import { getGoodsType, parcelForecastSingle, batchParcelForecast } from "@/api/system"
export default {
  data() {
    return {
      activeName: "single",
      form: {
        Codes: undefined,
        GoodsName: undefined,
        GoodsTypeId: undefined,
        Account: undefined,
        Price: undefined,
        total_price: undefined,
        ClientRem: undefined
      },
      content: "",
      loading: false,

    }
  },
  computed: {
    ...mapGetters(['singleReports', 'goodTypes']),
    rules() {
      return {
        Codes: [{ required: true, message: this.$t('apply.enterExpressNumber'), trigger: 'blur' }],
        GoodsName: [{ required: true, message: this.$t('apply.enterGoodsName'), trigger: 'blur' }],
        GoodsTypeId: [{ required: true, message: this.$t('apply.selectGoodsType'), trigger: 'blur' }],
        Account: [{ required: true, message: this.$t('apply.enterGoodsQuantity'), trigger: 'blur' }],
        Price: [{ required: true, message: this.$t('apply.enterUnitPrice'), trigger: 'blur' }],
        total_price: [{ required: true, message: this.$t('apply.enterTotalPrice'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleClick() {},
    handleChange() {
      if (this.form.Account && this.form.Price) {
        this.form.total_price = parseInt(this.form.Account) * parseInt(this.form.Price)
      }
    },
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.$store.dispatch('consolidation/changeGoodTypes', Data)
        if(!this.singleReports.length) this.addSingle()
      })
    },
    addSingle() {
      if (this.singleReports.length >= 10) {
        this.$message.warning("添加数量已达上限！")
        return;
      }
      this.$store.dispatch('consolidation/addArray')
    },
    removeSingle(){
      this.$store.dispatch('consolidation/removeArray')
    },
    validateSingle() {
      if (!this.singleReports) {
        this.$message({
          type: 'warning',
          message: this.$t('apply.enterInformation')
        });
        return false;
      } else {
        const allValuesAreNotEmpty = this.singleReports.map(item => { return {
          Code: item.Code, GoodsName: item.GoodsName,
          GoodsTypeId: item.GoodsTypeId, Account: item.Account,
          Price: item.Price, TotalPrice: item.TotalPrice } }).every(obj => {
          return Object.values(obj).every(value => value !== null && value !== undefined && value !== "");
        });
        if (!allValuesAreNotEmpty) {
          this.$message({
            type: 'warning',
            message: this.$t('apply.fillInCompleteInformation')
          });
          return false;
        } else {
          return true;
        }
      }
    },
    submit() {
      if (this.activeName === 'single') {
        if (!this.validateSingle()) {
          return;
        }
        parcelForecastSingle({ parcelForecastSingleInfos: this.singleReports }).then(response => {
          if (response.Success) {
            this.$message({
              type: 'success',
              message: this.$t('apply.reportSuccess')
            });
            this.$store.dispatch('consolidation/resetTemp');
          } else {
            this.$message({
              type: 'warning',
              message: response.Msg
            });
          }
        });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            const data = Object.assign({}, this.form);
            data['Codes'] = data['Codes'].split(/\s+/);
            batchParcelForecast(data).then(response => {
              this.loading = false;
              if (response.Success) {
                this.$message({ type: 'success', message: this.$t('apply.reportSuccess') });
                this.resetTemp();
              } else {
                this.$message({ type: 'warning', message: response.Msg });
              }
            }).catch(() => { this.loading = false; });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    resetTemp() {
      this.form = {
        Codes: undefined,
        GoodsName: undefined,
        GoodsTypeId: undefined,
        Account: undefined,
        Price: undefined,
        total_price: undefined,
        ClientRem: undefined
      }
    },
  },
  async created() {
    await this.$store.dispatch('webSite/getShopMatters', 11).then(data => {
      this.content = data.length ? data[0].Content : ""
    })
    this.getGoodsType()
  },
  components: {
    SingleCard
  }
}

</script>

<style scoped lang="scss">
.apply-container {
  padding: 4rem 2rem;
  .apply-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
  }
  .apply-box {
    .claim-box {
      border: 0.1rem solid #DCDEE2;
      .claim-title {
        display: flex;
        background-color: #F8F8F9;
        border-bottom: 0.1rem solid #DCDEE2;
        padding: 1rem 1.5rem;
        color: #737373;
        font-size: 1.4rem;
      }
      .claim-body {
        padding: 1.5rem;
        .operate {
          width: 100%;
          text-align: right;
          .operate-btn {
            background-color: #194D8F;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .common-box {
    .submit-btn {
      background-color: #194D8F;
      width: 10rem;
      //height: 4rem;
      //line-height: 4rem;
    }
    .prompt {
      white-space: nowrap;
      overflow: hidden;
      color: #6D6D6D;
      font-size: 1.2rem;
    }
  }

}
</style>
