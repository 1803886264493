<template>
  <div class="apply-container">
    <dedicated-line-order ref="dedicated"></dedicated-line-order>
  </div>
</template>

<script>
import DedicatedLineOrder from "@/components/dedicatedLineOrder.vue";
export default {
      data() {
        return {}
      },
      components: {
        DedicatedLineOrder
      },
      mounted() {
        this.$refs.dedicated.initializeForm()
      }
}
</script>

<style scoped lang="scss">
</style>
