<template>
  <div class="order-container">
    <div class="queryItems mb10">
      <el-input v-model="queryCode" size="small" class="mr15" clearable style="width: 300px" :placeholder="$t('order.placeholder')"></el-input>
      <el-button type="primary" class="" size="small" icon="el-icon-search" @click="getData">{{ $t('domestic.search') }}</el-button>
      <el-button size="small" class="mr15" icon="el-icon-delete" type="danger" @click="deleteOrder({})">{{ $t('order.delete') }}</el-button>
      <download-excel
          class="export-excel-wrapper"
          :data="tableData"
          :fields="fields"
          :name="timestampName"
          worksheet="sheet1"
      >
        <el-button size="small" icon="el-icon-upload2" type="primary">{{ $t('order.export') }}</el-button>
      </download-excel>
    </div>
    <div class="queryItems">
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.warehouse') }}：</div>
        <el-select size="small" v-model="querys.warehouse" clearable style="width: 20rem">
          <el-option
              v-for="item in options['warehouse']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.destination') }}：</div>
        <el-select size="small" v-model="querys.destination" clearable style="width: 20rem">
          <el-option
              v-for="item in options['destination']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.carrier') }}：</div>
        <el-select size="small" v-model="querys.carrier" clearable style="width: 20rem">
          <el-option
              v-for="item in options['carrier']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.timer') }}：</div>
        <el-select size="small" v-model="querys.timer" clearable style="width: 20rem">
          <el-option
              v-for="item in options['timer']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.goodstype') }}：</div>
        <el-select size="small" v-model="querys.goodsType" clearable style="width: 20rem">
          <el-option
              v-for="item in options['goodsType']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.CreateTime') }}：</div>
        <el-date-picker
            size="small"
            v-model="querys.dateRange"
            type="datetimerange"
            :range-separator="$t('order.separator')"
            :start-placeholder="$t('order.startTime')"
            :end-placeholder="$t('order.endTime')">
        </el-date-picker>
      </div>
    </div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="name in names" :key="name" :label="$t(`order.${name}`)" :name="name">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            :height="tableHeight"
            :max-height="tableHeight"
            v-loading="loading"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column :label="$t('order.mainbillcode')" prop="MainBillCode" align="center" width="180"></el-table-column>
          <el-table-column :label="$t('order.orderSourceName')" prop="OrderSourceName" align="center" width="100"></el-table-column>
          <el-table-column :label="$t('order.goodsname')" show-overflow-tooltip prop="GoodsName" align="center" width="180"></el-table-column>
          <el-table-column :label="$t('order.paystate')" align="center">
            <template slot-scope="scope">
              <div class="arrived-box" v-if="scope.row.PayState">{{ $t('order.paid') }}</div>
              <div class="unarrive-box" v-else>{{ $t('order.nonpayment') }}</div>
            </template>
          </el-table-column>
          <el-table-column label="入仓单号" show-overflow-tooltip prop="WarehouseEntryCode" align="center">
            <template slot-scope="scope">
              <el-popover
                  placement="right"
                  trigger="click">
                <div>
                  <el-button slot="reference" type="text" size="mini" @click="showTrajectory(scope.row.WarehouseEntryCode, true)">查询货态</el-button>
                  <el-button slot="reference" type="text" size="mini" @click="printClientCode(scope.row.WarehouseEntryCode)">打印入仓单号</el-button>
                  <el-button slot="reference" type="text" size="mini" @click="uploadBoxForm(scope.row)">上传箱单</el-button>
                  <el-button slot="reference" type="text" size="mini" @click="byteToPdf(scope.row.Id)">发货打印</el-button>
                </div>
                <el-button slot="reference" type="text" size="mini">{{ scope.row.WarehouseEntryCode }}</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="客户单号" show-overflow-tooltip prop="CustomerNumber" align="center">

          </el-table-column>
          <el-table-column prop="CarrierBillCode" :label="$t('order.carrierbillcode')" align="center" width="150px">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center; flex-direction: column">
                <el-button
                    style="margin: 0"
                    v-for="billCode in scope.row.CheckCodes"
                    :key="billCode"
                    type="text"
                    size="mini" @click="showTrajectory(billCode, false)">{{ billCode }}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.warehouse')" prop="WareHouseName" align="center"></el-table-column>
          <el-table-column :label="$t('order.carrier')" width="150" show-overflow-tooltip prop="CarrierName" align="center"></el-table-column>
          <el-table-column :label="$t('order.timer')" show-overflow-tooltip prop="TimerName" align="center"></el-table-column>
          <el-table-column :label="$t('order.goodstype')" prop="GoodsTypeName" align="center"></el-table-column>
          <el-table-column :label="$t('order.Weight')" prop="Weight" align="center"></el-table-column>
          <el-table-column :label="$t('order.CreateTime')" width="180" prop="CreateTime" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.CreateTime | formatDate }}</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              :label="$t('order.operate')"
              min-width="120px"
              align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.PayState && scope.row.IsReviews" type="text" size="mini" @click="showRateVisible(scope.row)">{{ $t('order.rate') }}</el-button>
              <el-button v-if="!scope.row.PayState && scope.row.Receivables" type="text" size="mini" @click="showPayment(scope.row)">{{ $t('order.payment') }}</el-button>
              <el-button type="text" size="mini" @click="editOrder(scope.row)">编辑</el-button>
              <el-button type="text" size="mini" @click="showOrder(scope.row.Id)">{{ $t('order.details') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            class="mt20"
            background
            @size-change="handleSizeChange"
            @current-change="handleChange"
            :current-page="PageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="PageRows"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        :title="$t('order.orderdetails')"
        :visible.sync="dialogVisible"
        top="1vh"
        center
        :close-on-click-modal="false"
    >
      <order-detail :order="order"></order-detail>
    </el-dialog>
    <el-dialog title="上传箱单" width="420px" :before-close="handleUploadClose" :visible.sync="boxFormVisible" center :close-on-click-modal="false">
      <el-upload
          class="upload"
          ref="upload"
          :multiple="false"
          :http-request="handleRequest"
          :before-upload="handleUpload"
          :on-exceed="handleExceed"
          :on-change="handleUploadChange"
          drag
          :limit="1"
          accept=".xls,.xlsx"
          :auto-upload="false"
          action="">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件，且不超过3M</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitBoxForm">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="订单编辑" width="80%" :visible.sync="dedicatedOrderVisible" center :close-on-click-modal="false">
      <dedicated-line-order @refresh="updateSuccess" ref="dedicated" mode="update" :order-data="currentRow"></dedicated-line-order>
    </el-dialog>
    <cashier :show.sync="show" :title="$t('order.cashier')" :order-type="`order`" :order-no="currentOrderNo" @close="closeDialog"></cashier>
    <comment-box @close="closeVisible" :show="commonVisible" :order-id="orderId"></comment-box>
    <trajectory-box @close="trajectoryVisible=false" :is-client="isClient" :show="trajectoryVisible" :carrier-bill-code="carrierBillCode"></trajectory-box>
    <div style="display: none; position: relative">
      <img id="barcode" style="width: 80%; position: absolute; top: 20%; left: 50%; transform: translateX(-50%)" src="" alt="">
    </div>
  </div>
</template>

<script>
import {
  deleteOrder,
  deliveryOrder,
  getDedicatedList,
  getOrderDetail,
  uploadTheOrderBoxList
} from "@/api/member";
import OrderDetail from "@/views/member/order/components/order-detail.vue"
import Cashier from "@/components/cashier.vue";
import CommentBox from "@/components/CommentBox.vue";
import TrajectoryBox from "@/views/member/order/components/TrajectoryBox.vue";
import {getPdfByte, printOrder, uploadImage} from "@/api/system";
import printJS from "print-js";
import JsBarcode from "jsbarcode";
import DedicatedLineOrder from "@/components/dedicatedLineOrder.vue";

export default {
  components: { OrderDetail, Cashier, CommentBox, TrajectoryBox, DedicatedLineOrder },
  data() {
    return {
      activeName: 'domestic',
      queryCode: "",
      names: ['domestic', 'unPaid', 'underway', 'shipped', 'completed'],
      dialogVisible: false,
      dedicatedOrderVisible: false,
      multipleSelection: [],
      options: {},
      querys: {
        dateRange: []
      },
      fileList: [],
      PageIndex: 1,
      PageRows: 10,
      total: 0,
      show: false,
      isClient: false,
      order: {},
      currentOrderNo: "",
      loading: false,
      commonVisible: false,
      trajectoryVisible: false,
      tableData: [],
      carrierBillCode: undefined,
      orderId: undefined,
      boxFormVisible: false,
      currentRow: {},
    }
  },
  created() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName
    }
    this.init()
    this.getData()
  },
  watch: {
    activeName() {
      this.PageIndex = 1;
      this.getData()
    }
  },
  computed: {
    fields() {
      const tableFields = {}
      tableFields[this.$t('order.id')] = 'Id'
      tableFields[this.$t('order.mainbillcode')] = 'MainBillCode'
      tableFields[this.$t('order.warehouse')] = 'WareHouseName'
      tableFields[this.$t('order.goodsname')] = 'GoodsName'
      tableFields[this.$t('order.paystate')] = 'PayState'
      tableFields[this.$t('order.carrierbillcode')] = 'CarrierBillCode'
      tableFields[this.$t('order.carrier')] = 'CarrierName'
      tableFields[this.$t('order.timer')] = 'TimerName'
      tableFields[this.$t('order.goodstype')] = 'GoodsTypeName'
      tableFields[this.$t('order.Weight')] = 'Weight'
      tableFields[this.$t('order.CreateTime')] = 'CreateTime'
      return tableFields
    },
    timestampName() {
      const timestamp = Date.now()
      return `${this.$t('order.exportOrder')}${timestamp}.xls`
    },
    totalCount() {
      return this.multipleSelection.length
    },
    orderType() {
      if (this.activeName === 'domestic') {
        return 0
      } else if (this.activeName === 'underway') {
        return 1
      } else if (this.activeName === 'shipped') {
        return 2
      } else {
        return 3
      }
    },
    tableHeight() {
      return 'calc(100vh - 17rem - 233px)'
    }
  },
  methods: {
    editOrder(row) {
      row['ClientCode'] = row['CustomerNumber']
      row['ChannelId'] = row['QuoteId'] || undefined
      row['WareHouseId'] = row['WareHouseId'] || undefined
      row['GoodsTypeId'] = row['GoodsTypeId'] || undefined
      row['CarrierId'] = row['CarrierId'] || undefined
      row['TimerId'] = row['TimerId'] || undefined
      this.currentRow = row
      this.dedicatedOrderVisible = true
      this.$nextTick(() => {
        this.$refs.dedicated.initializeForm()
      })
    },
    updateSuccess() {
      this.dedicatedOrderVisible = false
      this.getData()
      this.$message.success("修改成功")
    },
    handleRequest() {},
    uploadExcel() {
      return new Promise((resolve, reject) => {
        if (this.fileList.length) {
          const formData = new FormData();
          formData.append('file', this.fileList[0].raw || this.fileList[0]);
          formData.append('Path', 'OrderPackingList');
          this.loading = true
          uploadImage(formData).then(response => {
            const { Code, Msg, Data } = response
            if (Code === 200) {
              resolve(Data.ServerPath)
            } else {
              reject(Msg)
            }
          })
        } else {
          resolve("")
        }
      })
    },
    uploadBoxForm(row) {
      this.currentRow = row
      this.boxFormVisible = true
    },
    async submitBoxForm() {
      const ServerPath = await this.uploadExcel()
      const data = {
        OrderId: this.currentRow.Id,
        BoxListUrl: ServerPath
      }
      uploadTheOrderBoxList(data).then(response => {
        const { Code, Msg } = response
        if (Code === 200) {
          this.$message.success("上传成功!")
          this.boxFormVisible = false
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleUploadClose() {
      this.$refs.upload.clearFiles()
      this.boxFormVisible = false
    },
    handleExceed(files) {
      this.$refs.upload.clearFiles();
      this.fileList = [files[0]]
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error("文件超出大小");
      }
      return isLt2M;
    },
    printClientCode(code) {
      JsBarcode("#barcode", code, {
        fontSize: 40,
        width: 4,
        fontOptions: "bold",
      })
      setTimeout(() => {
        printJS({
          type: 'html',
          printable: "barcode",
          scanStyles: false, //不适用默认样式
          style: '@page{size:auto;margin: 0cm 1cm 0cm 1cm;opacity: 0;}',
        })
      }, 500)
    },
    deliveryOrder(item) {
      this.$confirm(this.$t('order.deliveryOrder.confirm', [item.Id]), this.$t('order.deliveryOrder.prompt'),
          {
            confirmButtonText: this.$t('order.deliveryOrder.confirmText'),
            cancelButtonText: this.$t('order.deliveryOrder.cancelText'),
            type: 'warning'
          }
      )
          .then(() => {
            const data = {
              Id: item.Id
            }
            deliveryOrder(data)
                .then(response => {
                  if (response.Success) {
                    this.$message({
                      type: 'success',
                      message: this.$t('order.deliveryOrder.success')
                    });
                    this.getData();
                  }
                })
                .catch(() => {});
          })
          .catch(() => {this.$message({type: 'info', message: this.$t('order.deliveryOrder.cancel')});
          });
    },
    init() {
      this.$store.dispatch('webSite/getTimer').then(data => {
        this.options['timer'] = data.map(item => {
          return { label: item.Name, value: item.Id }
        })
      })
      this.$store.dispatch('webSite/getCarrier').then(data => {
        this.options['carrier'] = data.map(item => {
          return { label: item.Name, value: item.Id }
        })
      })
      this.$store.dispatch('webSite/getGoodsType').then(data => {
        this.options['goodsType'] = data.map(item => {
          return { label: item.Name, value: item.Id }
        })
      })
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.options['warehouse'] = data.map(item => {
          return { label: item.Name, value: item.Id }
        })
      })
      this.$store.dispatch('webSite/getCountry').then(data => {
        this.options['destination'] = data.map(item => {
          return { label: item.Name, value: item.Id }
        })
      })
    },
    byteToPdf(code) {
      const data = {
        OrderCode: code,
        IsBatch: true,
        QueryCodeType: 2
      }
      getPdfByte(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          // const pdfurl = `data:application/pdf;base64,${Data[0]}`
          printJS({
            printable: Data,
            type: 'pdf',
            base64: true
          })
        } else {
          this.$message.warning(Msg)
        }

      })
    },
    closeVisible() {
      this.commonVisible = false
      this.getData()
    },
    showTrajectory(code, isClient) {
      this.isClient = isClient
      this.carrierBillCode = code
      this.trajectoryVisible = true
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    closeDialog(flag) {
      this.show = false
      if (flag === 'refresh') {
        this.getData()
      }
    },
    showRateVisible(row) {
      this.orderId = row.Id
      this.commonVisible = true
    },
    printPDF(orderId) {
      const data = {
        OrderId: orderId
      }
      printOrder(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          printJS(Data.SheetPath)
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleClick() {},
    handleChange(current) {
      this.PageIndex = current;
      this.getData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showOrder(id) {
      const data = {
        OrderId: id
      }
      getOrderDetail(data).then(response => {
        const { Code, Data, Msg } = response
        if (Code === 200) {
          this.order = Data;
          this.$forceUpdate()
          this.dialogVisible = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    deleteOrder(row = {}) {
      let orderId = '';
      if (Object.keys(row).length) {
        orderId = row.Id;
      } else {
        orderId = this.multipleSelection.map(item => item.Id).join('、');
      }
      this.$confirm(
          this.$t('order.deleteOrder.confirm', { orderId }),
          this.$t('order.deleteOrder.prompt'),
          {
            confirmButtonText: this.$t('order.deleteOrder.confirmText'),
            cancelButtonText: this.$t('order.deleteOrder.cancelText'),
            type: 'warning'
          }
      )
          .then(() => {
            let data = Object.keys(row).length ? [row.Id] : this.multipleSelection.map(item => item.Id);
            data = {
              Ids: data,
              IsRefund: true
            }
            deleteOrder(data)
                .then(response => {
                  if (response.Success) {
                    this.$message({
                      type: 'success',
                      message: this.$t('order.deleteOrder.success')
                    });
                    this.getData();
                  }
                })
                .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('order.deleteOrder.cancelDelete')
            });
          });
    },
    showPayment(row) {
      this.currentOrderNo = row.MainBillCode;
      this.show = true;
    },
    getData() {
      let QueryCodes = this.queryCode ? [this.queryCode] : undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        Codes: [],
        Type: this.orderType,
        QueryCodeType: 3,
        QueryCodes: QueryCodes,
        CarrierId: this.querys.carrier || undefined,
        TimerId: this.querys.timer || undefined,
        GoodsTypeId: this.querys.goodsType || undefined,
        StartTime: this.querys.dateRange.length ? this.querys.dateRange[0] : undefined,
        EndTime: this.querys.dateRange.length ? this.querys.dateRange[1] : undefined,
        WareHouseIds: this.querys.warehouse ? [this.querys.warehouse] : undefined,
        DestinationId: this.querys.destination || undefined,
        QueryType: 1,
        OrderSource: 8
      }
      if (this.activeName === 'unPaid') {
        data['PayState'] = 1
        data['Type'] = 0
      }
      this.loading = true
      getDedicatedList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          document.documentElement.scrollTop = 0;
          this.total = Total
          this.tableData = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
.default-font {
  font-size: 13px;
}
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.order-container {
  .queryItems {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
  }
  padding: 2rem 2.5rem;
  .merger-btn {
    background-color: #194D8F;
    width: 16rem;
  }
  .arrived-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #19B14C;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
  .unarrive-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #ED4014;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
}
</style>
