<template>
  <div>{{ text }}</div>
</template>

<script>
export default {
  name: "LinearGradientText",
  props: {
    text: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  //margin: 50px auto;
  font-size: 4.2rem;
  font-weight: bold;
  text-align: center;
  text-transform:uppercase;
  color: transparent;
  background: linear-gradient(45deg, #ffeb3b, #009688, yellowgreen, pink, #03a9f4, #9c27b0, #8bc34a);
  background-size: cover;
  background-position: center center;
  background-clip: text;
  animation: huerotate 1s infinite;
}

@keyframes huerotate {
  100% {
    filter: hue-rotate(360deg);
  }
}
</style>
