<template>
  <div class="core">
    <div class="core-title">{{ $t('corebox.title') }}</div>
    <div class="core-container">
      <div :style="{ animationDelay: `${ 0.5 * i }s`} " class="core-item animated fadeInRight" v-for="(item, i) in cores" :key="item.id">
        <el-image :src="require(`@/static/images/index/core-box/${item.image}`)" class="core-image"></el-image>
        <el-image :src="require(`@/static/images/${item.icon}`)" class="core-icon"></el-image>
        <div class="core-introduction">
          <div style="font-weight: 550; margin-bottom: 1.4rem;">{{ item.title }}</div>
          <div style="font-size: 1.1rem; color: #5E5E5E; line-height: 2.5em;">
            {{ item.notices }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    cores() {
      return  [
        {
          id: 1,
          title: this.$t('corebox.title1'),
          image: '1.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices1')
        },
        {
          id: 2,
          title: this.$t('corebox.title2'),
          image: '2.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices2')
        },
        {
          id: 3,
          title: this.$t('corebox.title3'),
          image: '3.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices2'),
        },
        {
          id: 4,
          title: this.$t('corebox.title4'),
          image: '4.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices4'),
        }
      ]
    }
  },
  data() {
    return {

    }
  }
}

</script>

<style scoped lang="scss">
.core-item:hover {
  transform: scale(1.1) !important;
}
.core {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 4rem;
  .core-title {
    font-size: 2.8rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 4rem;
  }
  .core-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48rem;
    .core-item {
      transition: transform 0.3s ease;
      animation-duration: 1s;
      background-color: #fff;
      flex-basis: 23%;
      height: 100%;
      border-radius: 0.5rem;
      position: relative;
      .core-image {
        width: 100%;
        height: 24rem;
      }
      .core-icon {
        width: 8rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .core-introduction {
        font-size: 1.6rem;
        position: absolute;
        top: 60%;
        padding: 0 1rem;
      }
    }
  }
}
</style>
