<template>
  <el-dialog :before-close="close" :title="$t('common.title')" width="20%" :visible.sync="show">
    <div class="body">
      <p>{{ $t('comment.title') }}</p>
      <el-rate
          v-model="form.StarLevel"
          show-text
          allow-half
          :texts="texts"
          :colors="colors">
      </el-rate>
      <p>{{ $t('comment.prompt') }}</p>
      <el-input
          type="textarea"
          :rows="3"
          :placeholder="$t('comment.placeholder')"
          v-model="form.ReviewContent">
      </el-input>
      <div slot="footer" class="dialog-footer mt20" style="text-align: center">
        <el-button style="width: 40%" round v-loading="loading" type="primary" @click="submit">{{ $t('common.submit') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import {addReviewsLog} from "@/api/user";
export default {
  name: "CommentBox",
  data() {
    return {
      loading: false,
      form: {
        StarLevel: 0,
        ReviewContent: "",
      },
      colors: ['#99A9BF', '#F7BA2A', '#FF9900']  // 等同于 { 2: '#99A9BF', 4: { value: '#F7BA2A', excluded: true }, 5: '#FF9900' }
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    OrderId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    texts() {
      return [this.$t('comment.veryPoor'), this.$t('comment.disappointed'), this.$t('comment.average'),
        this.$t('comment.satisfied'), this.$t('comment.verySatisfied')]
    },
  },
  methods: {
    submit() {
      const data = {
        ClientId: this.userInfo.Id,
        OrderId: this.OrderId,
        StarLevel: this.form.StarLevel,
        ReviewContent: this.form.ReviewContent
      }
      addReviewsLog(data).then(response => {
        const { Code, Msg, Success } = response
        if (Code === 200 && Success) {
          this.$message.success(this.$t('comment.callback'))
          this.close()
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  font-size: 1.5rem;
}
</style>
