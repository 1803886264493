<template>
  <div class="point-container">
    <div class="query-box">
      <el-cascader size="small" v-model="values" clearable @change="handleChange" style="width: 320px" :props="props"></el-cascader>
    </div>

    <div class="grid-box">
      <item v-for="data in listData" :key="data.Id" :item="data"></item>
    </div>

<!--    <el-table height="50rem" v-loading="loading" :data="listData" style="width: 100%" border>-->
<!--      <el-table-column prop="Name" :label="$t('pickupPoint.Name')" width="180"></el-table-column>-->
<!--      <el-table-column prop="TypeText" :label="$t('pickupPoint.TypeText')" width="180"></el-table-column>-->
<!--      <el-table-column prop="DestinationName" :label="$t('pickupPoint.DestinationName')" width="180"></el-table-column>-->
<!--      <el-table-column prop="ProvinceName" :label="$t('pickupPoint.ProvinceName')" width="180"></el-table-column>-->
<!--      <el-table-column prop="CityName" :label="$t('pickupPoint.CityName')" width="180"></el-table-column>-->
<!--      <el-table-column prop="AreaName" :label="$t('pickupPoint.AreaName')" width="180"></el-table-column>-->
<!--      <el-table-column prop="Address" :label="$t('pickupPoint.Address')" width="180"></el-table-column>-->
<!--      <el-table-column prop="Phone" :label="$t('pickupPoint.Phone')" width="180"></el-table-column>-->
<!--      <el-table-column prop="Tel" :label="$t('pickupPoint.Tel')" width="180"></el-table-column>-->
<!--      <el-table-column prop="BusinessHours" :label="$t('pickupPoint.BusinessHours')" width="180"></el-table-column>-->
<!--      <el-table-column prop="Postcode" :label="$t('pickupPoint.Postcode')" width="180"></el-table-column>-->
<!--      <el-table-column prop="ServiceCharge" :label="$t('pickupPoint.ServiceCharge')" width="180"></el-table-column>-->
<!--      <el-table-column prop="StorageLife" :label="$t('pickupPoint.StorageLife')" width="180"></el-table-column>-->
<!--      <el-table-column prop="Longitude" :label="$t('pickupPoint.Longitude')" width="180"></el-table-column>-->
<!--      <el-table-column prop="Latitude" :label="$t('pickupPoint.Latitude')" width="180"></el-table-column>-->
<!--      <el-table-column prop="Rem" :label="$t('pickupPoint.Rem')" width="180"></el-table-column>-->
<!--      <el-table-column prop="AgentStateText" :label="$t('pickupPoint.AgentStateText')" width="180"></el-table-column>-->
<!--    </el-table>-->
    <el-pagination
        class="mt20"
        background
        layout="prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageIndex"
        :current-change="getData"
        hide-on-single-page
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getAgent } from "@/api/member";
import { getCity, getProvince, getArea, getCountry } from "@/api/system";
import { mapGetters } from "vuex"
import Item from "./componetns/item.vue";

export default {
  data() {
    return {
      values: [],
      listData: [],
      pageSize: 10,
      loading: false,
      pageIndex: 1,
      total: 0,
      props: {
        lazy: true,
        checkStrictly: true,
        lazyLoad (node, resolve) {
          const { level, value } = node
          if (level === 0) {
            const data = {
              tenantId: this.TenantId,
              IsAgent: true
            }
            getCountry(data).then(response => {
              const { Data } = response
              resolve(Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else if (level === 1) {
            const data = {
              DestinationId: value,
              IsAgent: true
            }
            getProvince(data).then(response => {
              resolve(response.Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else if (level === 2) {
            const data = {
              ProvinceId: value,
              IsAgent: true
            }
            getCity(data).then(response => {
              resolve(response.Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else if (level === 3) {
            const data = {
              CityId: value,
              IsAgent: true
            }
            getArea(data).then(response => {
              resolve(response.Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else {
            resolve([])
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['TenantId'])
  },
  components: {
    Item
  },
  methods: {
    handleChange() {
      this.getData()
    },
    getData() {
      const data = {
        DestinationId: this.values[0] || undefined,
        ProvinceId: this.values[1] || undefined,
        CityId: this.values[2] || undefined,
        AreaId: this.values[3] || undefined,
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
      }
      this.loading = true
      getAgent(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  },
  created() {
    this.getData()
  }
}

</script>

<style scoped lang="scss">
.point-container {
  padding: 2rem 2.5rem;
  .query-box {
    margin-bottom: 2rem;
  }
  .grid-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}
</style>
