<template>
  <div class="coupon-box" :style="{'flex': `0 0 ${basicWidth}`, marginRight: basicWidth==='15%' ? '1rem' : 'none'}">
    <div class="coupon-header-box">
      <span class="media-font">{{ $t('coupon.discountcoupon')}}</span>
      <span class="title-font">{{ coupon.Amount }}</span>
    </div>
    <div class="coupon-introduce-box">
      <div class="pt15 pl15">
        <div class="mb8">{{ $t('coupon.effectivetime') }}：{{ coupon.EffectiveTime | formatDate }}</div>
        <div class="mb8">{{ $t('coupon.expiredtime') }}：{{ coupon.ExpiredTime | formatDate }}</div>
        <div class="mb8">{{ coupon.Name }}</div>
        <div>{{ coupon.Rem }}</div>
      </div>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-if="coupon.Status === 2">
      <button class="jump-btn" @click="$router.push({ path: '/member/consolidated-domestic' })">{{ $t('coupon.use') }}</button>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-else-if="coupon.Status === 4">
      <el-image :src="require('@/static/images/member/coupon/yishiyong.png')" style="height: 4.5rem"></el-image>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-else-if="coupon.Status === 1">
      <el-image :src="require('@/static/images/member/coupon/weishengxiao.png')" style="height: 4.5rem"></el-image>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-else-if="coupon.Status === 3">
      <el-image :src="require('@/static/images/member/coupon/yishixiao.png')" style="height: 4.5rem"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  computed: {
    basicWidth() {
      if (this.length <= 5) {
        return '15%'
        // return `${ 95 / this.length }%`
      } else {
        return '15%'
      }
    }
  },
  props: ['coupon', 'length']
}
</script>

<style scoped lang="scss">
.jump-btn {
  background-color: #fff;
  border: 1px solid rgba(248,138,77,1);
  width: 13rem;
  height: 3.6rem;
  border-radius: 1.8rem;
  color: rgba(248,138,77,1);
  font-size: 1.6rem;
  cursor: pointer;
}
.coupon-box {
  position: relative;
  height: 35rem; /* 根据需要设置矩形的高度 */
  border: 0.1rem solid #D8D8D8;
  border-radius: 0.5rem;
  display: inline-block;
  .coupon-header-box {
    height: 14rem;
    width: 100%;
    background-color: #FF7D35;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    .media-font {
      font-size: 2rem;
      color: #ffffff;
    }
    .title-font {
      font-size: 4rem;
      color: #ffffff;
      font-weight: bold;
    }
  }
  .coupon-introduce-box {
    width: 100%;
    height: 14rem;
    border-bottom: 0.1rem solid #D8D8D8;
    font-size: 1.2rem;
    color: #333333;
  }
}

.coupon-box::before,
.coupon-box::after {
  content: "";
  position: absolute;
  top: 14rem;
  width: 3rem; /* 根据需要设置凹槽的宽度 */
  height: 3rem; /* 半圆的高度等于矩形的高度 */
  background-color: #FFF; /* 与矩形的背景颜色相同 */
  border-radius: 50%; /* 使元素呈现半圆形状 */
  box-sizing: border-box; /* 调整盒模型，确保边框在元素内部 */
  overflow: hidden;
}

.coupon-box::before {
  left: 0;
  transform-origin: right; /* 设置变换的基准点为右侧边缘 */
  transform: translate(-50%, -50%); /* 将元素垂直居中对齐 */
  border-right: 0.1rem solid #D8D8D8;
}

.coupon-box::after {
  right: 0;
  transform-origin: left; /* 设置变换的基准点为左侧边缘 */
  transform: translate(50%, -50%); /* 将元素垂直居中对齐 */
  border-left: 0.1rem solid #D8D8D8;
}
</style>
