<template>
  <div class="menu">
    <div class="menu-item" v-for="item in menuItems" :key="item.id">
      <div :class="['u-start-flex', 'main-title', {active : currentMenu === item.id}]" @click="handleMenuClick(item)">
        <el-image :src="require(`@/static/images/member/nav-menu/${item.icon}`)" fit="cover" class="nav-img mr15"></el-image>
        <div>{{ item.title }}</div>
      </div>
      <ul v-show="expands[item.id]">
        <li :class="[{ 'nav-active' : activeItemId === subItem.id }]" v-for="subItem in item.subItems" :key="subItem.id" @click="handleItemClick(subItem)">
          {{ subItem.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      expands: {
        1: true,
        2: true,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false
      }
    };
  },

  computed: {
    ...mapGetters(['path', 'shopeeModal']),
    menuItems() {
      const routes = [
        {
          id: 1,
          title: this.$t('layout.member.overview'),
          path: '/member/personal',
          icon: 'diqiu.png',
          subItems: [
            { id: 11, title: this.$t('layout.member.accountSettings'), path: '/member/account' },
            { id: 12, title: this.$t('layout.member.myCoupons'), path: '/member/coupon' },
            { id: 21, title: this.$t('layout.member.domesticWarehouseAddress'), path: '/member/address-domestic' },
            // { id: 13, title: this.$t('layout.member.privateMessages'), path: '/member/letter' },
            { id: 14, title: this.$t('layout.member.flow'), path: '/member/flow' }
          ]
        },
        {
          id: 2,
          title: this.$t('layout.member.myDomesticWarehouse'),
          path: '',
          icon: 'ditu.png',
          subItems: [
            { id: 22, title: this.$t('layout.member.claimGoods'), path: '/member/claim-domestic' },
            // { id: 28, title: "专线下单", path: '/member/line' },
            { id: 27, title: this.$t('route.immediate'), path: '/member/immediate' },
            { id: 23, title: this.$t('layout.member.mergeGoods'), path: '/member/consolidated-domestic' },
            { id: 24, title: this.$t('layout.member.orderList'), path: '/member/order-domestic' },
            { id: 25, title: this.$t('layout.member.pickupPoint'), path: '/member/pickup-point' },
          ]
        },
        {
          id: 8,
          title: this.$t('layout.member.line'),
          path: '',
          icon: 'line.png',
          subItems: [
            { id: 81, title: this.$t('route.lineDomestic'), path: '/member/line' },
            { id: 82, title: this.$t('route.OrderLine'), path: '/member/order-line' }
          ]
        },
        {
          id: 7,
          title: this.$t('layout.member.same'),
          path: '',
          icon: 'same.png',
          subItems: [
            { id: 71, title: this.$t('route.upload'), path: '/member/same/upload' }
          ]
        },
        {
          id: 3,
          title: this.$t('layout.member.AgencyPromotion'),
          path: '',
          icon: 'daili.png',
          subItems: [
            { id: 31, title: this.$t('layout.member.AgentCenter'), path: '/member/agent' },
            // { id: 32, title: this.$t('layout.member.recommend'), path: '/member/recommend' },
          ]
        },
        {
          id: 4,
          title: this.$t('layout.member.AddedService'),
          path: '',
          icon: 'added.png',
          subItems: [
            { id: 41, title: this.$t('layout.member.addedType'), path: '/member/added' },
            { id: 42, title: this.$t('layout.member.addedList'), path: '/member/added-list' },
          ]
        }
      ]
      if (this.shopeeModal) {
        routes.push({
          id: 5,
          title: this.$t('layout.member.shopee'),
          path: '',
          icon: 'shopee.png',
          subItems: [
            { id: 51, title: this.$t('layout.member.shopeeShop'), path: '/member/shopee/shop' },
            { id: 52, title: this.$t('layout.member.shopeeOrder'), path: '/member/shopee/order' },
          ]
        })
      }
      routes.push({
        id: 6,
        title: this.$t('layout.member.notificationSettings'),
        path: '/member/notify',
        icon: 'xiaoxi.png',
        subItems: []
      })
      return routes
    },
    currentMenu() {
      if (!this.path) return undefined
      const menuItem = this.menuItems.find(item => item.path === this.path);
      if (menuItem) {
        return menuItem.id;
      }
      for (const item of this.menuItems) {
        const subItem = item.subItems.find(subItem => subItem.path === this.path);
        if (subItem) {
          return item.id;
        }
      }
      return undefined;
    },
    activeItemId() {
      if (!this.path) return undefined
      const menuItem = this.menuItems.find(item => item.subItems.some(subItem => subItem.path === this.path));
      if (menuItem) {
        const subItem = menuItem.subItems.find(subItem => subItem.path === this.path);
        return subItem.id;
      }
      return undefined
    }
  },
  created() {
  },
  methods: {
    handleItemClick(item) {
      if (item.path && item.path !== this.path) {
        this.$router.push({
          path: item.path
        })
      }
    },
    handleMenuClick(item) {
      if (item.path && item.path !== this.path) {
        this.$router.push({
          path: item.path
        })
      } else {
        this.expands[item.id]  = !this.expands[item.id]
      }
    }
  }
};
</script>

<style scoped lang="scss">
.menu {
  padding-top: 1.2rem;
  width: 100%;
  background-color: #fff;
  .menu-item {
    cursor: pointer;
    .main-title {
      padding: 1.8rem 2.5rem;
      font-size: 2.0rem;
      color: #333333;
      .nav-img {
        height: 1.8rem;
      }
    }
    .active {
      background-color: #194D8F;
      color: #FFFFFF;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center;
      font-size: 1.65rem;
      color: #333333;;
      li {
        cursor: pointer;
        padding: 1.2rem 0;
      }
      li.nav-active {
        color: #194D8F;
        background-color: #c6e1ff;
      }
    }
  }
}
</style>
