<template>
  <div class="card">
    <el-input size="mini" style="width: 22%" v-model="lineSize[index]['Length']" :placeholder="$t('line.length')"></el-input>
    <el-input size="mini" style="width: 22%" v-model="lineSize[index]['Width']" :placeholder="$t('line.width')"></el-input>
    <el-input size="mini" style="width: 22%" v-model="lineSize[index]['Height']" :placeholder="$t('line.height')"></el-input>
    <el-input size="mini" style="width: 22%" v-model="lineSize[index]['Quantity']" :placeholder="$t('line.quantity')"></el-input>
    <el-button type="text" style="width: 12%" size="mini" @click="removeLineSizeArray(index)">删除</el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['lineSize'])
  },
  data() {
    return {
    }
  },
  methods: {
    removeLineSizeArray(index) {
      this.$store.dispatch('consolidation/removeLineSizeArray', index)
    },
  }
}

</script>

<style scoped lang="scss">
.card {
  margin-bottom: 1rem;
}
</style>
