<template>
  <div class="exhibit">
    <div class="expanded-container">
      <div class="vhtml" v-html="content"></div>
    </div>
<!--    <div class="expanded-container">-->
<!--      <div class="exhibit-item" v-for="item in rightBox" :key="item.Id">-->
<!--        <div v-html="item.Content"></div>-->
<!--&lt;!&ndash;        <el-image style="width: 4.5rem; margin-right: 4rem" :src="require(`@/static/images/${item.icon}`)"></el-image>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="exhibit-title">&ndash;&gt;-->
<!--&lt;!&ndash;          <div style="font-size: 4rem">{{ item.title }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <div style="font-size: 1.2rem">{{ item.sub_title }}</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.$store.dispatch('webSite/getShopMatters', 14).then(data => {
      this.content = data[0].Content
    })
  },
  computed: {
    uppers() {
      return [
        {
          id: 1,
          icon: 'user.png',
          count: 10000,
          title: this.$t('exhibitBox.upper.members'),
          sub_title: this.$t('exhibitBox.upper.dailyGoodsWarehousing')
        },
        {
          id: 2,
          icon: 'user.png',
          title: this.$t('exhibitBox.upper.dailyGoodsStorage'),
          count: 10000,
          sub_title: this.$t('exhibitBox.upper.dailyOrders')
        },
        {
          id: 3,
          icon: 'user.png',
          title: this.$t('exhibitBox.upper.dailyOrders'),
          count: 10000,
          sub_title: this.$t('exhibitBox.upper.dailyShipments')
        }
      ]
    } ,
    lowers() {
      return [
        {
          id: 1,
          icon: 'user.png',
          title: this.$t('exhibitBox.lower.dailyShipments'),
          count: 10000,
          sub_title: this.$t('exhibitBox.lower.cooperativePickupPoints')
        },
        {
          id: 2,
          icon: 'user.png',
          count: 600,
          title: this.$t('exhibitBox.lower.cooperativePickupPoints'),
          sub_title: this.$t('exhibitBox.lower.dailyShipments')
        }
      ]
    }
  }
}

</script>

<style scoped lang="scss">
.exhibit {
  width: 100%;
  padding: 2rem 0;
  .expanded-container {
    .vhtml {
      width: 100%;
      overflow: hidden;
    }
  }
}
</style>
